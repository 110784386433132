import {gql} from "@apollo/client";

export const GET_LAYERS_BY_MAP = gql`
    query GetLayersWithRules {
        allCartoStaticMaps(filter: {interface: {equalTo: CLIENT}}) {
            nodes {
                id
                describe
                cartoMapLayerClientsByIdMap {
                    nodes {
                        idLayer
                        cartoLayerByIdLayer {
                            isActive
                            technicalName
                            nameFr
                            nameEn
                            order
                            id
                            cartoLayerRulesMappingsByIdLayer {
                                nodes {
                                    cartoStaticLayerRuleByIdRule {
                                        nameRule
                                        idRule
                                        cartoLayerRulesUsersByIdRule {
                                            nodes {
                                                idUser
                                            }
                                        }
                                        cartoLayerRulesGroupsByIdRule {
                                            nodes {
                                                idGroup
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        pad
                        zoomMax
                        zoomMin
                        isChecked
                        idMap
                    }
                }
                cartoMapLayerClientOverrideUsersByIdMap {
                    nodes {
                        idMap
                        idLayer
                        idUser
                        isChecked
                        zoomMax
                        zoomMin
                        nodeId
                    }
                }
                cartoMapLayerClientOverrideGroupsByIdMap {
                    nodes {
                        idGroup
                        idLayer
                        idMap
                        isChecked
                        zoomMax
                        zoomMin
                        nodeId
                    }
                }
            }
        }
    }
`;

export const GET_USER_BY_ID = gql`
    query GetUserById($id: Int!) {
        usersUserByIdUser(idUser: $id) {
            idGroup
            nameUser
            idUser
            usersStaticGroupByIdGroup {
                nameGroup
            }
        }
    }
`;

export const GET_GROUP_BY_ID = gql`
    query GetGroupsById($id: Int!) {
        usersStaticGroupByIdGroup(idGroup: $id) {
            idGroup
            nameGroup
        }
    }
`;

export const CREATE_USER_LAYER_OVERRIDE = gql`
    mutation CreateUserLayerOverride(
        $idUser: Int!,
        $idLayer: Int!,
        $idMap: Int!,
        $isChecked: Boolean!,
        $zoomMin: Int!,
        $zoomMax: Int!
    ) {
        createCartoMapLayerClientOverrideUser(
            input: {cartoMapLayerClientOverrideUser: {
                idUser: $idUser,
                idLayer: $idLayer,
                idMap: $idMap,
                isChecked: $isChecked,
                zoomMin: $zoomMin,
                zoomMax: $zoomMax,
            }}
        ) {
            clientMutationId
        }
    }
`;

export const UPDATE_USER_LAYER_OVERRIDE = gql`
    mutation UpdateUserLayerOverride(
        $idUser: Int!,
        $idLayer: Int!,
        $idMap: Int!,
        $cartoMapLayerClientOverrideUserPatch: CartoMapLayerClientOverrideUserPatch!
    ) {
        updateCartoMapLayerClientOverrideUserByIdUserAndIdMapAndIdLayer(
            input: {
                cartoMapLayerClientOverrideUserPatch: $cartoMapLayerClientOverrideUserPatch,
                idUser: $idUser,
                idMap: $idMap,
                idLayer: $idLayer
            }
        ) {
            clientMutationId
        }
    }
`;

export const DELETE_USER_LAYER_OVERRIDE = gql`
    mutation DeleteUserLayerOverride(
        $idUser: Int!,
        $idLayer: Int!,
        $idMap: Int!
    ) {
        deleteCartoMapLayerClientOverrideUserByIdUserAndIdMapAndIdLayer(
            input: {
                idUser: $idUser,
                idMap: $idMap,
                idLayer: $idLayer
            }
        ) {
            clientMutationId
        }
    }
`;

export const CREATE_GROUP_LAYER_OVERRIDE = gql`
    mutation CreateGroupLayerOverride(
        $idGroup: Int!,
        $idLayer: Int!,
        $idMap: Int!,
        $isChecked: Boolean!,
        $zoomMin: Int!,
        $zoomMax: Int!
    ) {
        createCartoMapLayerClientOverrideGroup(
            input: {cartoMapLayerClientOverrideGroup: {
                idGroup: $idGroup,
                idLayer: $idLayer,
                idMap: $idMap,
                isChecked: $isChecked,
                zoomMin: $zoomMin,
                zoomMax: $zoomMax,
            }}
        ) {
            clientMutationId
        }
    }
`;

export const UPDATE_GROUP_LAYER_OVERRIDE = gql`
    mutation UpdateGroupLayerOverride(
        $idGroup: Int!,
        $idLayer: Int!,
        $idMap: Int!,
        $cartoMapLayerClientOverrideGroupPatch: CartoMapLayerClientOverrideGroupPatch!
    ) {
        updateCartoMapLayerClientOverrideGroupByIdGroupAndIdMapAndIdLayer(
            input: {
                cartoMapLayerClientOverrideGroupPatch: $cartoMapLayerClientOverrideGroupPatch,
                idGroup: $idGroup,
                idMap: $idMap,
                idLayer: $idLayer
            }
        ) {
            clientMutationId
        }
    }
`;

export const DELETE_GROUP_LAYER_OVERRIDE = gql`
    mutation DeleteGroupLayerOverride(
        $idGroup: Int!,
        $idLayer: Int!,
        $idMap: Int!
    ) {
        deleteCartoMapLayerClientOverrideGroupByIdGroupAndIdMapAndIdLayer(
            input: {
                idGroup: $idGroup,
                idMap: $idMap,
                idLayer: $idLayer
            }
        ) {
            clientMutationId
        }
    }
`;
