import React, {useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {gql, useLazyQuery, useQuery} from "@apollo/client";
import {GET_RULE} from "./RuleQuery";
import {RuleForm} from "./RuleForm";

const GET_RULES_IDS = gql`
    query GetRulesIds {
        allCartoStaticLayerRules {
            nodes {
                idRule
            }
        }
    }
`;

function RuleSingle() {

    const { id } = useParams();
    const { state } = useLocation();
    const { error, data } = useQuery(GET_RULE, {
        variables: { id: Number(id) },
    });


    const [getRulesIds, ruleIdRequest] = useLazyQuery(GET_RULES_IDS);

    const [rule, setRule] = React.useState(null);

    useEffect(() => {
        if (data) {
            setRule(data.cartoStaticLayerRuleByIdRule);
        }

        if (id === 'new') {
            if (state?.idLayer) {
                setRule({
                    layer: [state?.idLayer],
                });
            }
            getRulesIds();
        }
    }, [id, error, data, state]);

    useEffect(() => {
        if (ruleIdRequest.data) {
            const lastId = Array.from(ruleIdRequest.data?.allCartoStaticLayerRules?.nodes).sort((a, b) => {
                return b.idRule - a.idRule;
            }).shift().idRule;


            setRule({
                idRule: lastId + 1,
            })
        }
    }, [ruleIdRequest.data]);

    return (
        <div className="container mt-4 mx-auto">
            {rule && <RuleForm rule={rule} />}
        </div>
    );
}

export default RuleSingle;
