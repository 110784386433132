import {getSetupFromSession} from "../index";
import {STATIC_ADMIN_PAD_LIST, STATIC_CLIENT_PAD_LIST} from "../assets/Static";

export const customRequest = (operationName, query) => {
    const {uri, jwt} = getSetupFromSession();

    return fetch(uri, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + jwt,
        },
        body: JSON.stringify({
            operationName: operationName,
            query: query,
        }),
    })
}

export const getPadName = (id, mapInterface) => {
    const list = mapInterface === 'ADMIN' ? STATIC_ADMIN_PAD_LIST : STATIC_CLIENT_PAD_LIST;
    const pad =  list.find(pad => pad.id === id)

    return pad ? `${pad.title}` : 'Inconnu';
}

export const newUniqueId = (array) => {
    let id = 1;

    while (array.find((a) => a === id)) {
        id++;
    }
    
    return id
}

export const updateOrCreate = async (updateFn, createFn) => {
    try {
        return await updateFn();
    } catch (e) {
        return await createFn();
    }
}
