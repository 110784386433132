import React, {useEffect, useRef, useState} from "react";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {useMutation} from "@apollo/client";
import {CREATE_MAP} from "./MapQuery";
import {Messages} from "primereact/messages";
import {Controller, useForm} from "react-hook-form";
import {classNames} from "primereact/utils";
import {RadioButton} from "primereact/radiobutton";

function MapModalEdit({map, setMap, onMutation}) {

    const [createMutation] = useMutation(CREATE_MAP, {
        onError: (error) => {
            console.log('[MapModalEdit] -> createMutation(error) -> ', error)
        },
    });

    const [editMapId, setEditMapId] = useState(false);

    const msgs = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const { control, formState: {errors }  , setValue, handleSubmit } = useForm({
        defaultValues: {
            describe: map?.describe ?? '',
            interface: map?.interface ?? '',
            id: map?.id ?? '',
        }
    });

    useEffect(() => {
        if (map) {
            setEditMapId(false)
            setIsLoading(false);

            setValue('describe', map.describe);
            setValue('interface', map.interface);
            setValue('id', map.id);

            setVisible(true);
        }
    }, [map]);

    const saveOrCreateMap = (data) => {
        setIsLoading(true);
            // create map
        createMutation({
            variables: {
                cartoStaticMap: {
                    describe: data.describe,
                    interface: data.interface,
                    id: Number(data.id),
                }
            }
        }).then((res) => {
            if (res.errors) {
                msgs.current.show({ severity: 'error', summary: 'Error', detail: res.errors.message, sticky: true });
            } else {
                setVisible(false);
                setMap(null);

                if (onMutation) {
                    onMutation();
                }
            }
            setIsLoading(false);
        })
    }

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    const footer = (
        <div>
            <Button disabled={isLoading} severity={'success'} label="Créer" icon="pi pi-check" className="p-button-text" onClick={handleSubmit(saveOrCreateMap)} />
            <Button disabled={isLoading} outlined label="Annuler" icon="pi pi-times" className="p-button-text" onClick={() => setVisible(false)} />
        </div>
    );

    return (
        <Dialog visible={visible} style={{ width: '450px' }} header="Map Details" modal className="p-fluid" footer={footer} onHide={() => setVisible(false)}>
            <div className="field">
                <label htmlFor="id">ID (automatique)</label>
                <div>
                    <Controller
                        name="id"
                        control={control}
                        render={({ field, fieldState }) => (
                            <>
                                <div className="p-inputgroup">
                                    <InputText id={field.name} disabled={!editMapId} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    <Button placeholder="Bottom"  tooltip="Editer manuellement" tooltipOptions={{ event: 'hover' }} icon="fa-duotone fa-pen-to-square" className="p-button-danger" onClick={() => setEditMapId(true)}/>
                                </div>
                                {getFormErrorMessage(field.name)}
                            </>

                        )}
                    />
                </div>
            </div>

            <div className="field">
                <label htmlFor="describ">Nom</label>

                <Controller
                    name="describe"
                    control={control}
                    rules={{ required: 'name is required' }}
                    render={({ field, fieldState }) => (
                        <>
                            <span className="p-float-label">
                                <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>

                    )}
                />
            </div>

            <div className="field">
                <label htmlFor="interface">Interface</label>

                <Controller
                    name="interface"
                    control={control}
                    rules={{ required: 'Value is required.' }}
                    render={({ field }) => (
                        <>
                            <div className="flex justify-content-center">
                                <div className="flex align-items-center">
                                    <RadioButton inputId="f5" {...field} inputRef={field.ref} value="ADMIN" checked={field.value === 'ADMIN'} />
                                    <label htmlFor="f5" className="ml-1 mr-3">
                                        Admin
                                    </label>

                                    <RadioButton inputId="f6" {...field} inputRef={field.ref} value="CLIENT" checked={field.value === 'CLIENT'} />
                                    <label htmlFor="f6" className="ml-1 mr-3">
                                        Client
                                    </label>
                                </div>
                            </div>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
            </div>

            <Messages ref={msgs} />
        </Dialog>
    );
}

export default MapModalEdit;