import React, {useEffect, useRef, useState} from "react";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Messages} from "primereact/messages";
import {Controller, useForm} from "react-hook-form";
import {classNames} from "primereact/utils";
import {SelectLayer} from "../../ui/SelectLayer";
import {InputNumber} from "primereact/inputnumber";
import {InputSwitch} from "primereact/inputswitch";

function ModelOverrideLayer({el, onMutation}) {


    const msgs = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isNew] = useState(!el?.layer?.idLayer);
    const [visible, setVisible] = useState(false);

    const { control, formState: {errors }, setValue, handleSubmit } = useForm({
        defaultValues: {
            type: el?.type,
            nodeId: el?.nodeId,
            idMap: el?.idMap,
            idLayer: el?.idLayer,
            zoomMin: el?.zoomMin ?? 0,
            zoomMax: el?.zoomMax ?? 18,
            isChecked: el?.isChecked,
        }
    });


    useEffect(() => {
        if (el) {
            setIsLoading(false);

            // set form values
            setValue('idMap', el?.idMap);
            setValue('type', el?.type);
            setValue('nodeId', el?.nodeId);
            setValue('idLayer', el?.idLayer);
            setValue('zoomMin', Number(el?.zoomMin));
            setValue('zoomMax', Number(el?.zoomMax));
            setValue('isChecked', el?.isChecked);

            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [el]);


    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    const footer = (
        <div>
            <Button disabled={isLoading} severity={'success'} label={isNew ? 'Créer' : 'Modifier'} icon="fa fa-check" onClick={handleSubmit(onMutation)} />
            <Button disabled={isLoading} outlined label="Annuler" onClick={() => setVisible(false)} />
        </div>
    );

    const header = (
        <div className="flex gap-3 align-items-center">
            <label htmlFor="isChecked" className="font-bold">
                Actif par default
            </label>
            <Controller
                name="isChecked"
                control={control}
                render={({ field, fieldState, onChange }) => (
                    <InputSwitch
                        checked={field.value}
                        onChange={field.onChange}
                    />
                )}
            />
        </div>
    );

    return (
        <Dialog visible={visible} style={{ width: '450px' }} header={header} modal className="p-fluid" footer={footer} onHide={() => setVisible(false)}>
            <div className="field">
                <label htmlFor="describeEn"  className="font-bold">Layers concernés</label>
                <div>
                    <Controller
                        name="idLayer"
                        control={control}
                        rules={{ required: 'Champ requis' }}
                        render={({ field, fieldState, onChange }) => (
                            <>
                                <SelectLayer control={control}
                                             disabled={true}
                                             id={field.name}
                                             single
                                             name={field.name}
                                             value={field.value}
                                             className={classNames({ 'p-invalid': fieldState.invalid })}
                                             onChange={field.onChange}
                                />
                                {getFormErrorMessage(field.name)}
                            </>
                        )}
                    />
                </div>
            </div>

            <div className="formgrid grid">
                <div className="field col">
                    <label htmlFor="zoomMin" className="font-bold">
                        Zoom MIN
                    </label>

                    <Controller
                        name="zoomMin"
                        control={control}
                        render={({ field, fieldState, onChange }) => (
                            <InputNumber id="zoomMin" value={field.value} onChange={(e) => field.onChange(e.value)}
                                         showButtons buttonLayout="horizontal" step={1}
                                         incrementButtonIcon="fa fa-plus"
                                         decrementButtonIcon="fa fa-minus"
                                         min={0} max={18} />
                        )}
                    />
                </div>
                <div className="field col">
                    <label htmlFor="zoomMax" className="font-bold">
                        Zoom MAX
                    </label>
                    <Controller
                        name="zoomMax"
                        control={control}
                        render={({ field, fieldState, onChange }) => (
                            <InputNumber id="zoomMax" value={field.value} onChange={(e) => field.onChange(e.value)}
                                         showButtons buttonLayout="horizontal" step={1}
                                         incrementButtonIcon="fa fa-plus"
                                         decrementButtonIcon="fa fa-minus"
                                         min={0} max={18} />
                        )}
                    />
                </div>
            </div>

            <Messages ref={msgs} />
        </Dialog>
    );
}

export default ModelOverrideLayer;