import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles.scss';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import MapList from "./page/maps/MapList";
import LayerList from "./page/layers/LayerList";
import {ApolloClient, ApolloProvider, InMemoryCache} from '@apollo/client';
import App from "./App";
import LayerSingle from "./page/layers/LayerSingle";
import MapSingle from "./page/maps/MapSingle";
import RuleList from "./page/rules/RuleList";
import RuleSingle from "./page/rules/RuleSingle";
import Simulator from "./page/simulator/Simulator";
import {Setup} from "./Setup";
import {PrimeReactProvider} from "primereact/api";
import ErrorPage from "./ErrorPage";
import {jwtDecode} from "jwt-decode";

export const safeJwtDecode = (jwt) => {
    try {
        return jwtDecode(jwt);
    } catch (e) {
        return null;
    }
}

export const router = createBrowserRouter([
    {
        path: "/setup",
        element: <Setup />,
    }, {
        path: "/error-page",
        element: <ErrorPage />,
    }, {
        path: "/",
        errorElement: <ErrorPage />,
        element: <App />,
        children: [{
            path: "",
            element: <LayerList />,
        },{
            path: "/layers",
            element: <LayerList />,
        }, {
            path: "/layers/:id",
            element: <LayerSingle />,
        },{
            path: "/map",
            element: <MapList />,
        },{
            path: "/map/:id",
            element: <MapSingle />,
        },{
            path: "/rules",
            element: <RuleList />,
        }, {
            path: "/rules/:id",
            element: <RuleSingle/>,
        },{
            path: "/override",
            element: <Simulator />,
        },{
            path: "/override/user/:idUser",
            element: <Simulator />,
        },{
            path: "/override/group/:idGroup",
            element: <Simulator />,
        }],
    }
]);


const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
}


export const getSetupFromUrl = () => {
    let params = new URLSearchParams(document.location.search);

    if (!params.get('jwt') || !params.get('graphql_url')) {
        return null;
    }

    sessionStorage.setItem('jwt', params.get('jwt'));
    sessionStorage.setItem('graphql_url', params.get('graphql_url'));

    return {
        jwt: params.get('jwt'),
        uri: params.get('graphql_url')
    }
}
export const getSetupFromSession = () => {
    const jwt = sessionStorage.getItem('jwt');
    const uri = sessionStorage.getItem('graphql_url');

    if (!jwt || !uri) {
        return null;
    }

    return {
        jwt: jwt,
        uri: uri
    }
}

export const initSetup = () => {

    const {uri, jwt} = getSetupFromUrl() || getSetupFromSession() || {
        uri: '',
        jwt: '',
    };

    if (jwt) {
        const decoded = safeJwtDecode(jwt);

        if (!decoded) {
            sessionStorage.removeItem('jwt');
            sessionStorage.removeItem('graphql_url');
            window.location.href = '/setup?type=jwt-invalid';
        }

        const now = new Date();
        const exp = new Date(decoded.exp * 1000);
        
        if (exp < now) {
            sessionStorage.removeItem('jwt');
            sessionStorage.removeItem('graphql_url');
            window.location.href = '/setup?type=jwt-expired';
        }
    }

    if (uri && jwt) {
        return {
            uri: uri,
            headers: {
                'Authorization': `Bearer ${jwt}`,
            }
        }
    }


    if (!window.location.href.includes('setup')) {
        window.location.href = '/setup';
    }
}

export let apollo = new ApolloClient({
    ...initSetup(),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ApolloProvider client={apollo}>
          <PrimeReactProvider>
              <RouterProvider router={router} />
          </PrimeReactProvider>
      </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
