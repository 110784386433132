import React, {useEffect, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FilterMatchMode} from "primereact/api";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {GET_LAYERS} from "./LayerQuery";
import { Chip } from 'primereact/chip';
import {Card} from "primereact/card";
import {Skeleton} from "primereact/skeleton";
import EmptyTable from "../../ui/EmptyTable";

function LayerList() {

    let navigate = useNavigate();

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const { loading, error, data } = useQuery(GET_LAYERS);

    const [layers, setLayers] = React.useState([]);
    const [selectedLayer] = React.useState(null);

    useEffect(() => {
        if (data) {
            setLayers(data.allCartoLayers.nodes.map((layer) => {
                return {
                    ...layer,
                    rule: layer.cartoLayerRulesMappingsByIdLayer.nodes.map((mapping) => {
                        return mapping.cartoStaticLayerRuleByIdRule
                    })[0]?.nameRule
                }
            }));
        }
    }, [loading, error, data]);

    if (loading) return (
        <div className="container mt-4 mx-auto">
            <div className="flex mb-4 align-items-start flex-column lg:justify-content-between lg:flex-row">
                <div>
                    <div className="font-medium text-3xl text-900">Layers</div>
                    <div className="flex align-items-center text-700 flex-wrap">
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-users mr-2"></i>
                            <span><Skeleton size="1rem"/></span>
                        </div>
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-globe mr-2"></i>
                            <span><Skeleton size="1rem"/></span>

                        </div>
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-globe mr-2"></i>
                            <span><Skeleton size="1rem"/></span>

                        </div>
                    </div>
                </div>
            </div>

            {EmptyTable(['Nom', 'Interface', 'Description'], 30)}
        </div>

    );

    const getPublicLayersCount = () => {
        return layers?.filter((layer) => {
            return !!layer.rule;
        }).length
    }

    const getInactiveLayersCount = () => {
        return layers?.filter((layer) => {
            return !layer.isActive;
        }).length
    }


    const ruleTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                {!!rowData.rule && <Chip label={rowData.rule} icon="fa-duotone fa-lock-keyhole" />}
            </div>
        );
    };

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <span className="p-input-icon-left">
                <i className="fa-duotone fa-search" />
                <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
            </span>
        );
    };

    const nameTemplate = (layer) => {
        return (<span>{layer.nameFr}</span>);
    };

    const isActiveTemplate = (layer) => {
        return layer.isActive ? '' : (
            <span className="ml-2 bg-red-600 text-white border-round p-1">
                <i className="fa fa-exclamation-triangle mr-1"></i>
                Layer désactivé
            </span>
        )}

    const header = renderHeader();

    const goToLayer = (layer) => {
        navigate(`/layers/${layer.id}`)
    }

    return (
        <div className="container mt-4 mx-auto">
            <div className="flex mb-4 align-items-start flex-column lg:justify-content-between lg:flex-row">
                <div>
                    <div className="font-medium text-3xl text-900">Layers</div>
                    <div className="flex align-items-center text-700 flex-wrap">
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-users mr-2"></i>
                            <span>{layers?.length} Layers</span>
                        </div>
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-globe mr-2"></i>
                            <span>{getPublicLayersCount()} Public</span>
                        </div>
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-globe mr-2"></i>
                            <span>{getInactiveLayersCount()} Inactif</span>
                        </div>
                    </div>
                </div>
                <div className="mt-3 lg:ml-2">
                    <Button label="Ajouter un layer" onClick={() => goToLayer({id: 'new'})} className="p-button-outlined mr-2" icon="fa-duotone fa-layer-plus mr-2" />
                </div>
            </div>

            <Card>
                <DataTable value={layers} scrollable scrollHeight="70vh" header={header} filters={filters} onFilter={(e) => setFilters(e.filters)}
                           selection={selectedLayer} selectionMode={'single'} onRowSelect={(e) => goToLayer(e.data)}
                           stateStorage="session" stateKey="layerList" emptyMessage="Aucun layer trouvé." tableStyle={{ minWidth: '50rem' }}>
                    <Column field="id" header="Id" sortable></Column>
                    <Column field="nameFr" header="Nom" body={nameTemplate} sortable></Column>
                    <Column field="isActive" header="Desactiver" body={isActiveTemplate} sortable></Column>
                    <Column field="type" header="Type" sortable></Column>
                    <Column field="rule" header="Layer privé" body={ruleTemplate} sortable></Column>
                </DataTable>
            </Card>
        </div>
    );
}

export default LayerList;
