import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {GET_LAYER, GET_LAYERS_IDS} from "./LayerQuery";
import {useLazyQuery, useQuery} from "@apollo/client";
import {LayerForm} from "./LayerForm";
function LayerSingle() {

    const { id } = useParams();
    const { error, data, refetch } = useQuery(GET_LAYER, {
        variables: {
            id: Number(id)
        },
    });

    const [getLayersIds, layerIdRequest] = useLazyQuery(GET_LAYERS_IDS);

    const [layer, setLayer] = React.useState(null);

    useEffect(() => {
        if (layer) {
            setLayer(null)

            refetch()
        }
    }, [id]);

    useEffect(() => {
        if (data) {
            setLayer(data.cartoLayerById);
        }

        if (id === 'new') {
            getLayersIds();
        }

    }, [id, error, data]);

    useEffect(() => {
        if (layerIdRequest.data) {
            const lastHigherId = Array.from(layerIdRequest.data?.allCartoLayers?.nodes).sort((a, b) => {
                return b.id - a.id;
            }).shift().id;

            setLayer({
                type: 'DEFAULT',
                isActive: false,
                id: lastHigherId + 1,
            })
        }
    }, [layerIdRequest.data]);

    return (
        <div className="container mt-4 mx-auto">
            {layer && <LayerForm layer={layer} refetch={() => refetch()} />}
        </div>
    );
}

export default LayerSingle;
