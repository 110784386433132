import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import React, {useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import {classNames} from "primereact/utils";
import {safeJwtDecode} from "./index";
import {Toast} from "primereact/toast";

export const Setup = () => {
    const toast = React.useRef(null);
    console.log('[] -> Setup(navigate) -> ', 'ntm')
    
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const type = params.get('type');

        if (type === 'jwt-invalid') {
            toast.current.show({severity: 'error',
                summary: 'Erreur',
                detail: 'Le token est invalide.',
                life: 1000000
            });
        }

        if (type === 'jwt-expired') {
            toast.current.show({severity: 'error',
                summary: 'Token expiré',
                detail: 'Pour vous authentifier automatiquement, utilisez le super admin.',
                life: 1000000
            });
        }
    }, []);

    const {
        control,
        handleSubmit,
        setError,
        formState: {
            errors
        }
    } = useForm({
        defaultValues: {
            url: '',
            token: '',
        }
    });

    const submit = (data) => {
        sessionStorage.setItem('graphql_url', data.url);
        sessionStorage.setItem('jwt', data.token);

        window.location.href = '/';
    }



    const isValidJwt = (jwt) => {
        const decoded = safeJwtDecode(jwt);

        if (!decoded) {
            setError('token', {
                type: 'manual',
                message: 'Token invalide.'
            });
            return
        }

        const now = new Date();
        const exp = new Date(decoded.exp * 1000);

        if (exp < now) {
            setError('token', {
                type: 'manual',
                message: 'Token expiré.'
            });
        } else {
            setError('token', {
                type: 'manual',
                message: null
            });
        }
    }

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : null;
    };

    return (
        <div className="flex justify-content-center align-items-center h-screen">
            <Toast ref={toast} />
            <div className="card border border-1 p-4 border-round" style={{minWidth: '450px'}}>
                <h1 className="text-center">
                    <i className="fa-duotone fa-layer-group"></i> SAL
                </h1>
                <div className="p-fluid gap-3 flex-column flex">
                    <div className="p-field">
                        <label htmlFor="url">Url API GraphQl</label>
                        <Controller
                            name="url"
                            control={control}
                            rules={{ required: 'Champ obligatoire.' }}
                            render={({ field, fieldState }) => (
                                <>
                                    <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} />
                                    {getFormErrorMessage(field.name)}
                                </>
                            )}
                        />
                    </div>
                    <div className="p-field">
                        <label htmlFor="token">JWT</label>
                        <Controller
                            name="token"
                            control={control}
                            rules={{ required: 'Champ obligatoire.' }}
                            render={({ field, fieldState }) => (
                                <>
                                    <InputText id={field.name} {...field}
                                                onKeyUp={(e) => {
                                                    isValidJwt(e.target.value)
                                                }}
                                               className={classNames({ 'p-invalid': fieldState.error })} />
                                    {getFormErrorMessage(field.name)}
                                </>
                            )}
                        />
                    </div>

                    <Button type="button" label="🚀 Configurer" onClick={handleSubmit(submit)} />
                </div>
            </div>
        </div>
    );
}

