


export const STATIC_CLIENT_PAD_LIST = [
    {id: 100, localisation: 'Panel layer', title: "Données de pluies", description: ''},
    {id: 11, localisation: 'Panel layer', title: "Zones inondables actuelles (étude EGIS)", description: ''},
    {id: 12, localisation: 'Panel layer', title: "Zones inondables projet (étude EGIS)", description: ''},
    {id: 13, localisation: 'Panel layer', title: "Enjeux et vulnérabilités » (étude EGIS)", description: ''},
    {id: 10, localisation: 'Panel layer', title: "Données Haïtidata.org", description: ''},
    {id: 20, localisation: 'Panel layer', title: "Données inondation GEOCAPA", description: ''},
    {id: 21, localisation: 'Panel layer', title: "Données enjeux GEOCAPA", description: ''},
    {id: 22, localisation: 'Panel layer', title: "Données moyens GEOCAPA", description: ''},
    {id: 0, localisation: 'Panel layer', title: "Autres données", description: ''},
    {id: 110, localisation: 'Panel layer', title: "Données DGM", description: ''},
    {id: 1, localisation: 'Panel layer', title: "OBSERVATIONS ET PREVISIONS METEOROLOGIQUES", description: 'Spécifique layer Anywhere'},
    {id: 2, localisation: 'Panel layer', title: "INONDATIONS ET RUISSELLEMENTS", description: 'Spécifique layer Anywhere'},
    {id: 3, localisation: 'Panel layer', title: "Vagues de Chaleur", description: 'Spécifique layer Anywhere'},
    {id: 4, localisation: 'Panel layer', title: "FEUX DE FORET LIEES AUX INTEMPERIES", description: 'Spécifique layer Anywhere'},
    {id: 5, localisation: 'Panel layer', title: "RAFALES DE VENT", description: 'Spécifique layer Anywhere'},
    {id: 6, localisation: 'Panel layer', title: "ORAGES", description: 'Spécifique layer Anywhere'},
    {id: 7, localisation: 'Panel layer', title: "CHUTES DE NEIGE", description: 'Spécifique layer Anywhere'},
]


export const STATIC_ADMIN_PAD_LIST = [
    {id: 1, title: "Données de pluies", description: ''},
    {id: 2, title: "Données orages", description: ''},
    {id: 3, title: "Autres données", description: ''},
    {id: 100, title: "Usager", description: ''},
]
