import {useRouteError} from "react-router-dom";
import React from "react";

export default function ErrorPage() {
    const error = useRouteError();

        return (<div className="flex justify-content-center align-items-center h-screen">
            <div className="card border border-1 p-4 border-round">
                <h1 className="text-center">
                    <i className="fa-duotone fa-layer-group"></i> SAL
                </h1>
                <div>
                    <p>An error has occurred.</p>
                    {error && (
                        <p>{error.error?.message}</p>
                    )}
                </div>
            </div>
        </div>
    );
}