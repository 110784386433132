import React, {useEffect} from 'react';
import {Dropdown} from "primereact/dropdown";
import {STATIC_ADMIN_PAD_LIST, STATIC_CLIENT_PAD_LIST} from "../assets/Static";


export const SelectPad = ({ label, value, mapInterface, onChange, control, className, currentGroups, optionLabel, optionValue, multiple, ...props }) => {

    const [options, setOptions] = React.useState([]);

    useEffect(() => {

        if (mapInterface === 'ADMIN') {
            setOptions([...STATIC_ADMIN_PAD_LIST].map(pad => ({...pad, name: `${pad?.id} - ${pad?.title}`})));
        } else {
            setOptions([...STATIC_CLIENT_PAD_LIST].map(pad => ({...pad, name: `${pad?.id} - ${pad?.title}`})));
        }
    }, [mapInterface, value]);


    return (
          <Dropdown options={options}
                    id={"pad"}
                    multiple={false}
                    value={value}
                    optionValue={"id"}
                    optionLabel={"name"}
                    className={className}
                    onChange={(e) => onChange(e.value)}
          ></Dropdown>
    )

}
