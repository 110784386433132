import {gql} from "@apollo/client";


export const GET_RULES = gql`
    query GetRulesMapping {
        allCartoStaticLayerRules {
            nodes {
                nameRule
                idRule
            }
        }
        allCartoLayerRulesUsers {
            nodes {
                idUser
                idRule
                usersUserByIdUser {
                    nameUser
                }
            }
        }
        allCartoLayerRulesGroups {
            nodes {
                idRule
                idGroup
                usersStaticGroupByIdGroup {
                    nameGroup
                }
            }
        }
        allCartoLayerRulesMappings {
            nodes {
                idLayer
                idRule
                cartoLayerByIdLayer {
                    nameFr
                }
            }
        }
    }
`;

export const GET_RULE = gql`
    query GetRule($id: Int!) {
        cartoStaticLayerRuleByIdRule(idRule: $id) {
            nameRule
            idRule
            cartoLayerRulesUsersByIdRule {
                nodes {
                    usersUserByIdUser {
                        nameUser
                        idUser
                        nodeId
                    }
                }
            }
            cartoLayerRulesMappingsByIdRule {
                nodes {
                    cartoLayerByIdLayer {
                        nameFr
                        id
                        nodeId
                    }
                }
            }
            cartoLayerRulesGroupsByIdRule {
                nodes {
                    usersStaticGroupByIdGroup {
                        idGroup
                        nameGroup
                        nodeId
                    }
                }
            }
            cartoLayerRulesExemptUsersByIdRule {
                nodes {
                    idUser
                }
            }
        }
    }
`;

export const CREATE_RULE_MUTATION = gql`
    mutation CreateRule($name: String!, $idRule: Int!) {
        createCartoStaticLayerRule(input: {cartoStaticLayerRule: {
            nameRule: $name,
            idRule: $idRule
        }}) {
            cartoStaticLayerRule {
                idRule
                nameRule
            }
        }
    }
`;

export const DELETE_RULE_MUTATION = gql`
    mutation DeleteRule($idRule: Int!) {
        deleteCartoStaticLayerRuleByIdRule(input: {idRule: $idRule}) {
            cartoStaticLayerRule {
                idRule
            }
        }
    }
`;

export const UPDATE_RULE_MUTATION = (id, name, layers, groups, users, excludedUser, layersToAdd, groupsToAdd, usersToAdd, excludedUserToAdd) => {
    let baseMutation = `
        updateCartoStaticLayerRuleByIdRule(input: {idRule: ${id}, cartoStaticLayerRulePatch: {nameRule: "${name}"}}) {
            cartoStaticLayerRule {
                nameRule
            }
        }
    `;

    if (layers?.length > 0) {
        layers.forEach((layer, idx) => {
            baseMutation += `
                layerDeleted_${idx}: deleteCartoLayerRulesMappingByIdRuleAndIdLayer(input: {idLayer: ${layer}, idRule: ${id}}) {
                    clientMutationId
                }
            `;
        });
    }

    if (groups?.length > 0) {
        groups.forEach((group, idx) => {
            baseMutation += `
                groupDeleted_${idx}: deleteCartoLayerRulesGroupByIdGroupAndIdRule(input: {idGroup: ${group}, idRule: ${id}}) {
                    clientMutationId
                }
            `;
        });
    }

    if (users?.length > 0) {
        users.forEach((user, idx) => {
            baseMutation += `
                userDeleted_${idx}: deleteCartoLayerRulesUserByIdUserAndIdRule(input: {idUser: ${user}, idRule: ${id}}) {
                    clientMutationId
                }
            `;
        });
    }

    if (excludedUser?.length > 0) {
        excludedUser.forEach((user, idx) => {
            baseMutation += `
                excludedUserDeleted_${idx}: deleteCartoLayerRulesExemptUserByIdUserAndIdRule(input: {idUser: ${user}, idRule: ${id}}) {
                    clientMutationId
                }
            `;
        });
    }

    if (layersToAdd?.length > 0) {
        layersToAdd.forEach((layer, idx) => {
            baseMutation += `
                layerAdded_${idx}: createCartoLayerRulesMapping(input: {cartoLayerRulesMapping: {idLayer: ${layer}, idRule: ${id}}}) {
                    clientMutationId
                }
            `;
        });
    }

    if (groupsToAdd?.length > 0) {
        groupsToAdd.forEach((group, idx) => {
            baseMutation += `
                groupAdded_${idx}: createCartoLayerRulesGroup(input: {cartoLayerRulesGroup: {idRule: ${id}, idGroup: ${group}}}) {
                    clientMutationId
                }
            `;
        });
    }

    if (usersToAdd?.length > 0) {
        usersToAdd.forEach((user, idx) => {
            baseMutation += `
                userAdded_${idx}: createCartoLayerRulesUser(input: {cartoLayerRulesUser: {idRule: ${id}, idUser: ${user}}}) {
                    clientMutationId
                }
            `;
        });
    }

    if (excludedUserToAdd?.length > 0) {
        excludedUserToAdd.forEach((user, idx) => {
            baseMutation += `
                excludedUserAdded_${idx}: createCartoLayerRulesExemptUser(input: {cartoLayerRulesExemptUser: {idUser: ${user}, idRule: ${id}}}) {
                    clientMutationId
                }
            `;
        });
    }

    return `mutation UpdateRule {
            ${baseMutation}
        }
    `;
}


export const DELETE_RULES_MUTATION = (id, layers, groups, users, excludedUser) => {
    let deleteEntities = '';

    if (layers?.length > 0) {
        layers.forEach((layer, idx) => {
            deleteEntities += `
                layerDeleted_${idx}: deleteCartoLayerRulesMappingByIdRuleAndIdLayer(input: {idLayer: ${layer}, idRule: ${id}}) {
                    clientMutationId
                }
            `;
        });
    }

    if (groups?.length > 0) {
        groups.forEach((group, idx) => {
            deleteEntities += `
                groupDeleted_${idx}: deleteCartoLayerRulesGroupByIdGroupAndIdRule(input: {idGroup: ${group}, idRule: ${id}}) {
                    clientMutationId
                }
            `;
        });
    }

    if (users?.length > 0) {
        users.forEach((user, idx) => {
            deleteEntities += `
                userDeleted_${idx}: deleteCartoLayerRulesUserByIdUserAndIdRule(input: {idUser: ${user}, idRule: ${id}}) {
                    clientMutationId
                }
            `;
        });
    }

    if (excludedUser?.length > 0) {
        excludedUser.forEach((user, idx) => {
            deleteEntities += `
                excludedUserDeleted_${idx}: deleteCartoLayerRulesExemptUserByIdUserAndIdRule(input: {idUser: ${user}, idRule: ${id}}) {
                    clientMutationId
                }
            `;
        });
    }

    return `mutation DeleteRule {
            ${deleteEntities}
            
            deleteCartoStaticLayerRuleByIdRule(input: {idRule: ${id}}) {
                clientMutationId
            }
        }
    `;
}

export const REMOVE_RULE_LAYER = gql`
    mutation RemoveRuleLayer($idRule: Int!, $idLayer: Int!) {
        deleteCartoLayerRulesMappingByIdRuleAndIdLayer(input: {idLayer: $idLayer, idRule: $idRule}) {
            clientMutationId
        }
    }
`;

export const ADD_RULE_LAYER = gql`
    mutation AddRuleLayer($idRule: Int!, $idLayer: Int!) {
        createCartoLayerRulesMapping(input: {cartoLayerRulesMapping: {idLayer: $idLayer, idRule: $idRule}}) {
            clientMutationId
        }
    }
`;