import './styles.css';
import MainNavigation from "./ui/Menu";
import React, {useEffect, useRef} from "react";
import {Outlet} from "react-router-dom";
import {Toast} from "primereact/toast";

export const globalToast = (toast) => {
    const ev = new CustomEvent('onToast', {
        bubbles: true,
        cancelable: true,
        detail: toast
    });

    window.dispatchEvent(ev);
}

function App() {
    const toast = useRef(null);

    useEffect(() => {
        
        const onToast = (ev) => {
            if (ev.type === "onToast" && ev.detail) {
                toast.current.show(ev.detail);
            }
        };

        if (!window.onToast) {
            window.addEventListener("onToast", onToast, false);

            window.onToast = (ev) => {
                onToast(ev);
            }
        }

        return () => {
            window.addEventListener("onToast", onToast, false);
        };
    }, []);

    return (
    <div className="App">
        <Toast ref={toast}/>

        <MainNavigation />

        <Outlet />
    </div>
  );
}

export default App;
