import React, { useEffect, useState } from 'react';
import {gql, useQuery} from "@apollo/client";
import {MultiSelect} from "primereact/multiselect";
import {Skeleton} from "primereact/skeleton";
import {Dropdown} from "primereact/dropdown";

const GET_LAYERS = gql`
    query GetGroups {
        allCartoLayers {
            nodes {
                id
                nameFr
            }
        }
    }
`;

export const SelectLayer = ({ single, disabled, name, id, className, label, value, onChange, ...props }) => {

    const { data } = useQuery(GET_LAYERS);

    const [layers, setLayers] = useState([]);
    const [filteredLayers, setFilteredLayers] = useState([]);

    useEffect(() => {
        if (data) {
            const _layers = data.allCartoLayers.nodes.map(layer => {
                return {
                    ...layer,
                    value: layer.id,
                    name: `${layer.id} - ${layer.nameFr}`
                }
            })

            setLayers(_layers);
            setFilteredLayers(_layers);
        }
    }, [data]);

    const _onChange = (e) => {
        onChange(e.value);
    }

    if (!layers.length) {
        return <Skeleton width="100%" height={'44px'}></Skeleton>
    }

    if (single) {
        return (
            <Dropdown id={id}
                      name={name}
                      value={value}
                      disabled={!layers.length || disabled}
                      optionValue={"id"}
                      optionLabel={"name"}
                      filter={true}
                      filterBy={"name"}
                      className={"w-full"}
                      placeholder={"Sélectionner un layer"}
                      onChange={(e) => onChange(e.value)}
                      options={filteredLayers}
            />
        )
    }

    return (
        <MultiSelect id={id}
                     name={name}
                     value={value}
                     filter
                     selectionLimit={single ? 1 : null}
                     disabled={!layers.length || disabled}
                     filterBy={"name"}
                     display={"chip"}
                     className={"w-full"}
                     virtualScrollerOptions={{ itemSize: 43 }}
                     options={layers}
                     placeholder={"Sélectionner un ou plusieurs layers"}
                     optionLabel="name"
                     onChange={_onChange}
        />
    )

}