import { Button } from 'primereact/button';
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {FilterMatchMode} from "primereact/api";
import {InputText} from "primereact/inputtext";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import { useQuery } from '@apollo/client';
import {Tag} from "primereact/tag";
import { Skeleton } from 'primereact/skeleton';
import EmptyTable from "../../ui/EmptyTable";
import {GET_MAPS} from "./MapQuery";
import {Card} from "primereact/card";
import MapModalEdit from "./MapModalEdit";


function MapList() {

    let navigate = useNavigate();

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const [maps, setMaps] = React.useState([]);
    const [selectedMap, setSelectedMap] = React.useState(null);

    const { loading, error, data, refetch } = useQuery(GET_MAPS);

    useEffect(() => {
        if (data) {
            setMaps(data.allCartoStaticMaps.nodes);
        }
    }, [loading, error, data]);

    if (loading) return (
        <div className="container mt-4 mx-auto">
            <div className="flex mb-4 align-items-start flex-column lg:justify-content-between lg:flex-row">
                <div>
                    <div className="font-medium text-3xl text-900">Maps</div>
                    <div className="flex align-items-center text-700 flex-wrap">
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-users mr-2"></i>
                            <span><Skeleton size="1rem"/></span>
                        </div>
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-globe mr-2"></i>
                            <span><Skeleton size="1rem"/></span>

                        </div>
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-globe mr-2"></i>
                            <span><Skeleton size="1rem"/></span>

                        </div>
                    </div>
                </div>
            </div>

            {EmptyTable(['Nom', 'Interface', 'Description'], 30)}
        </div>

    );

    const getInterfaceMapCount = (type) => {
        return maps?.filter((layer) => {
            return layer.interface === type
        }).length
    }


    const ruleTemplate = (rowData) => {
        return rowData?.interface === 'ADMIN'
            ? <Tag className="mr-2" icon="pi pi-check" style={{background: '#f08'}} value="ADMIN"></Tag>
            : <Tag className="mr-2" icon="pi pi-check" style={{background: '#00ade5'}} value="CLIENT"></Tag>
    };

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <span className="p-input-icon-left">
                <i className="fa-duotone fa-search" />
                <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
            </span>
        );
    };

    const header = renderHeader();

    const goToMap = (layer) => {
        setSelectedMap(layer)
        navigate(`/map/${layer.id}`)
    }

    const newUniqueId = () => {
        let id = 1;

        while (maps.find((map) => map.id === id)) {
            id++;
        }

        return id
    }

    const addMap = () => {
        setSelectedMap({
            id: newUniqueId(),
            describe: '',
            interface: 'ADMIN',
        })
    }


    return (
        <div className="container mt-4 mx-auto">
            <div className="flex mb-4 align-items-start flex-column lg:justify-content-between lg:flex-row">
                <div>
                    <div className="font-medium text-3xl text-900">Maps</div>
                    <div className="flex align-items-center text-700 flex-wrap">
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-users mr-2"></i>
                            <span>{maps?.length} Maps</span>
                        </div>
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-globe mr-2"></i>
                            <span>{getInterfaceMapCount('ADMIN')} ADMIN</span>
                        </div>
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-globe mr-2"></i>
                            <span>{getInterfaceMapCount('CLIENT')} CLIENT</span>
                        </div>
                    </div>
                </div>
                <div className="mt-3 lg:ml-2">
                    <Button label="Ajouter une map" className="p-button-outlined mr-2" icon="fa-duotone fa-layer-plus mr-2" onClick={addMap} />
                </div>
            </div>

            <Card>
                <DataTable value={maps} scrollable scrollHeight="70vh" header={header} filters={filters} onFilter={(e) => setFilters(e.filters)}
                           selectionMode={'single'} onRowSelect={(e) => goToMap(e.data)}
                           stateStorage="session" stateKey="mapList" emptyMessage="Aucun layer trouvé." tableStyle={{ minWidth: '50rem' }}>
                    <Column field="id" header="ID" sortable></Column>
                    <Column field="describe" header="Nom" sortable></Column>
                    <Column field="interface" header="Interface" body={ruleTemplate}  sortable></Column>
                </DataTable>
            </Card>

            <MapModalEdit map={selectedMap} setMap={setSelectedMap} onMutation={() => refetch()}/>
        </div>
    );
}

export default MapList;
