import React, {useEffect, useRef, useState} from "react";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {useMutation} from "@apollo/client";
import {
    ADD_LAYER_TO_MAP_ADMIN,
    ADD_LAYER_TO_MAP_CLIENT,
    UPDATE_MAP_LAYER_ADMIN, UPDATE_MAP_LAYER_CLIENT
} from "./MapQuery";
import {Messages} from "primereact/messages";
import {Controller, useForm} from "react-hook-form";
import {classNames} from "primereact/utils";
import {SelectLayer} from "../../ui/SelectLayer";
import {SelectPad} from "../../ui/SelectPad";
import {InputNumber} from "primereact/inputnumber";
import {InputSwitch} from "primereact/inputswitch";
import {RadioButton} from "primereact/radiobutton";

function MapModelAddLayer({el, onMutation}) {


    const msgs = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isNew, setIsNew] = useState(!el?.layer?.idLayer);
    const [isAdminLayer, setIsAdminLayer] = useState(false);
    const [visible, setVisible] = useState(false);

    const { control, formState: {errors }, setValue, handleSubmit } = useForm({
        defaultValues: {
            idMap: el?.layer?.idMap,
            idLayer: el?.layer?.idLayer,
            pad: el?.layer?.pad,
            idTypeAdmin: el?.layer?.idTypeAdmin,
            selectedTypeAdmin: [],
            zoomMin: el?.layer?.zoomMin ?? 0,
            zoomMax: el?.layer?.zoomMax ?? 18,
            isChecked: el?.layer?.isChecked,
        }
    });

    const [addLayerToMapAdmin] = useMutation(ADD_LAYER_TO_MAP_ADMIN, {
        onError: (error) => {
            console.log('[MapModelAddLayer] -> addLayerToMapAdmin(error) -> ', error)
        }
    });
    const [addLayerToMapClient] = useMutation(ADD_LAYER_TO_MAP_CLIENT, {
        onError: (error) => {
            console.log('[MapModelAddLayer] -> addLayerToMapClient(error) -> ', error)
        }
    });

    const [updateLayerAdmin] = useMutation(UPDATE_MAP_LAYER_ADMIN);
    const [updateLayerClient] = useMutation(UPDATE_MAP_LAYER_CLIENT);


    useEffect(() => {
        if (el?.layer) {
            setIsLoading(false);

            setIsAdminLayer(!!el?.isTypeAdmin)
            setIsNew(!el?.layer?.idLayer)

            // set form values
            setValue('idMap', el.layer?.idMap);
            setValue('idLayer', el.layer?.idLayer);
            setValue('pad', el.layer?.pad);
            setValue('idTypeAdmin', el.layer?.idTypeAdmin);
            setValue('zoomMin', Number(el.layer?.zoomMin));
            setValue('zoomMax', Number(el.layer?.zoomMax));
            setValue('isChecked', el.layer?.isChecked);


            setVisible(true);
        }
    }, [el]);

    const upsertLayer = (layer) => {
        setIsLoading(true);

        let _selectedLayer = { ...layer };

        if (isNew) {
            const createRequest = isAdminLayer
                ? addLayerToMapAdmin({
                    variables: {
                        cartoMapLayerAdmin: {
                            idLayer: _selectedLayer.idLayer,
                            idMap: _selectedLayer.idMap,
                            idTypeAdmin: _selectedLayer.idTypeAdmin,
                            zoomMin: _selectedLayer.zoomMin,
                            zoomMax: _selectedLayer.zoomMax,
                            isChecked: _selectedLayer.isChecked,
                            pad: _selectedLayer.pad,
                        }
                    }
                })
                : addLayerToMapClient({
                    variables: {
                        cartoMapLayerClient: {
                            idLayer: _selectedLayer.idLayer,
                            idMap: _selectedLayer.idMap,
                            zoomMin: _selectedLayer.zoomMin,
                            zoomMax: _selectedLayer.zoomMax,
                            isChecked: _selectedLayer.isChecked,
                            pad: _selectedLayer.pad,
                        }
                    }
                })

            createRequest.then((response) => {
                if (!!response.data) {
                    setVisible(false);
                    onMutation({
                        type: 'add',
                        layer: _selectedLayer
                    });
                } else {
                    msgs.current.show({
                        severity: 'error',
                        sticky: true,
                        content: errorMessages(response.errors)
                    });
                }
                setIsLoading(false)
            });

        } else {
            const updateRequest = isAdminLayer
                ? updateLayerAdmin({
                    variables: {
                        idLayer: _selectedLayer.idLayer,
                        idMap: _selectedLayer.idMap,
                        idTypeAdmin: _selectedLayer.idTypeAdmin,
                        cartoMapLayerAdminPatch: {
                            zoomMin: _selectedLayer.zoomMin,
                            zoomMax: _selectedLayer.zoomMax,
                            isChecked: _selectedLayer.isChecked,
                            pad: _selectedLayer.pad,
                        }
                    }
                })
                : updateLayerClient({
                    variables: {
                        idLayer: _selectedLayer.idLayer,
                        idMap: _selectedLayer.idMap,
                        cartoMapLayerClientPatch: {
                            zoomMin: _selectedLayer.zoomMin,
                            zoomMax: _selectedLayer.zoomMax,
                            isChecked: _selectedLayer.isChecked,
                            pad: _selectedLayer.pad,
                        }
                    }
                })

            updateRequest.then((response) => {
                if (!!response.data) {
                    setVisible(false);
                    onMutation({
                        type: 'update',
                        layer: _selectedLayer
                    })
                } else {
                    msgs.current.show({
                        severity: 'error',
                        sticky: true,
                        content: errorMessages(response.errors)
                    });
                }

                setIsLoading(false)
            });
        }
    };

    const errorMessages = (errors) => {
        return (
            <>
                <div className="flex flex-column gap-3">
                    <div className="flex align-items-center gap-3 font-bold">
                        <i className="fa fa-exclamation-triangle" style={{ fontSize: '2rem' }} />
                        <div>
                            Erreur
                        </div>
                    </div>
                    <div>
                        {errors?.message}
                    </div>
                </div>
            </>
        )
    }


    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    const footer = (
        <div>
            <Button disabled={isLoading} severity={'success'} label={isNew ? 'Créer' : 'Modifier'} icon="fa fa-check" onClick={handleSubmit(upsertLayer)} />
            <Button disabled={isLoading} outlined label="Annuler" onClick={() => setVisible(false)} />
        </div>
    );

    const header = (
        <div className="flex gap-3 align-items-center">
            <label htmlFor="isChecked" className="font-bold">
                Actif par default
            </label>
            <Controller
                name="isChecked"
                control={control}
                render={({ field, fieldState, onChange }) => (
                    <InputSwitch
                        checked={field.value}
                        onChange={field.onChange}
                    />
                )}
            />
        </div>
    );

    return (
        <Dialog visible={visible} style={{ width: '450px' }} header={header} modal className="p-fluid" footer={footer} onHide={() => setVisible(false)}>
            <div className="field">
                <label htmlFor="describeEn"  className="font-bold">Layers concernés</label>
                <div>
                    <Controller
                        name="idLayer"
                        control={control}
                        rules={{ required: 'Champ requis' }}
                        render={({ field, fieldState, onChange }) => (
                            <>
                                <SelectLayer control={control}
                                             id={field.name}
                                             single
                                             name={field.name}
                                             value={field.value}
                                             className={classNames({ 'p-invalid': fieldState.invalid })}
                                             onChange={field.onChange}
                                />
                                {getFormErrorMessage(field.name)}
                            </>
                        )}
                    />
                </div>
            </div>

            <div className="field">
                <label htmlFor="description" className="font-bold">
                    Pad ( emplacement )
                </label>
                <Controller
                    name="pad"
                    control={control}
                    rules={{ required: 'Champ requis' }}
                    render={({ field, fieldState, onChange }) => (
                        <>
                            <SelectPad control={control}
                                       id={field.name}
                                       name={field.name}
                                       value={field.value}
                                        mapInterface={isAdminLayer ? 'ADMIN' : 'CLIENT'}
                                       className={classNames({ 'p-invalid': fieldState.invalid })}
                                       onChange={field.onChange}

                            />
                            {getFormErrorMessage(field.name)}
                        </>
                    )} />
            </div>

            <div className="formgrid grid">
                <div className="field col">
                    <label htmlFor="zoomMin" className="font-bold">
                        Zoom MIN
                    </label>

                    <Controller
                        name="zoomMin"
                        control={control}
                        render={({ field, fieldState, onChange }) => (
                            <InputNumber id="zoomMin" value={field.value} onChange={(e) => field.onChange(e.value)}
                                         showButtons buttonLayout="horizontal" step={1}
                                         incrementButtonIcon="fa fa-plus"
                                         decrementButtonIcon="fa fa-minus"
                                         min={0} max={18} />
                        )}
                    />
                </div>
                <div className="field col">
                    <label htmlFor="zoomMax" className="font-bold">
                        Zoom MAX
                    </label>
                    <Controller
                        name="zoomMax"
                        control={control}
                        render={({ field, fieldState, onChange }) => (
                            <InputNumber id="zoomMax" value={field.value} onChange={(e) => field.onChange(e.value)}
                                         showButtons buttonLayout="horizontal" step={1}
                                         incrementButtonIcon="fa fa-plus"
                                         decrementButtonIcon="fa fa-minus"
                                         min={0} max={18} />
                        )}
                    />
                </div>
            </div>

            {isAdminLayer && (
                <div className="field">
                    <label htmlFor="interface" className="font-bold">Interface</label>

                    <Controller
                        name="idTypeAdmin"
                        control={control}
                        rules={{ required: 'Champ requis.' }}
                        render={({ field }) => (
                            <>
                                <div className="flex justify-content-center">
                                    <div className="flex align-items-center">
                                        <RadioButton inputId="f5" {...field} inputRef={field.ref} value="FRANCE" disabled={!isNew} checked={field.value === 'FRANCE'} />
                                        <label htmlFor="f5" className="ml-1 mr-3">
                                            FRANCE
                                        </label>

                                        <RadioButton inputId="f6" {...field} inputRef={field.ref} value="INTERNATIONAL" disabled={!isNew} checked={field.value === 'INTERNATIONAL'} />
                                        <label htmlFor="f6" className="ml-1 mr-3">
                                            INTERNATIONAL
                                        </label>
                                    </div>
                                </div>
                                {getFormErrorMessage(field.name)}
                            </>
                        )}
                    />
                </div>
            )}

            <Messages ref={msgs} />
        </Dialog>
    );
}

export default MapModelAddLayer;
