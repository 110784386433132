import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {gql, useMutation, useQuery} from "@apollo/client";
import {
    ADD_LAYER_TO_MAP_ADMIN,
    ADD_LAYER_TO_MAP_CLIENT, DELETE_MAP,
    GET_MAP, REMOVE_MAP_LAYER_ADMIN,
    REMOVE_MAP_LAYER_CLIENT,
    UPDATE_MAP_LAYER_ADMIN, UPDATE_MAP_LAYER_CLIENT
} from "./MapQuery";
import {Skeleton} from "primereact/skeleton";
import EmptyTable from "../../ui/EmptyTable";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FilterMatchMode} from "primereact/api";
import {InputText} from "primereact/inputtext";
import {Tag} from "primereact/tag";
import {Toast} from "primereact/toast";
import {Card} from "primereact/card";
import {InputSwitch} from "primereact/inputswitch";
import {confirmPopup, ConfirmPopup} from "primereact/confirmpopup";
import MapModelAddLayer from "./MapModalAddLayer";
import {Tooltip} from "primereact/tooltip";

const GET_LAYERS_STATUS = gql`
    query GetGroups {
        allCartoLayers {
            nodes {
                id
                isActive
            }
        }
    }
`;
function MapSingle() {

    const { id } = useParams();
    const { error, data, loading, refetch } = useQuery(GET_MAP, {
        variables: { id: Number(id) },
    });

    const layerStatus = useQuery(GET_LAYERS_STATUS);

    const navigate = useNavigate()

    const [map, setMap] = React.useState(null);
    const [layers, setLayers] = React.useState([]);
    const [selectedLayer, setSelectedLayer] = React.useState(null);
    const [activeLayerList, setActiveLayerList] = React.useState([]);
    const toast = useRef(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });


    const [removeMap] = useMutation(DELETE_MAP, {
        update(cache, { data: { cartoStaticMapById } }) {
            cache.modify({
                fields: {
                    allCartoStaticMaps(existingMapRefs, { readField, canRead }) {
                        return existingMapRefs.nodes.filter(
                            (mapRef) => {
                                return data.cartoStaticMapById.id !== readField("id", mapRef)
                            }
                        );
                    }
                }
            })
        }
    });

    const [addLayerToMapAdmin] = useMutation(ADD_LAYER_TO_MAP_ADMIN);
    const [addLayerToMapClient] = useMutation(ADD_LAYER_TO_MAP_CLIENT);

    const [updateLayerAdmin] = useMutation(UPDATE_MAP_LAYER_ADMIN);
    const [updateLayerClient] = useMutation(UPDATE_MAP_LAYER_CLIENT);

    const [removeLayerAdmin] = useMutation(REMOVE_MAP_LAYER_ADMIN);
    const [removeLayerClient] = useMutation(REMOVE_MAP_LAYER_CLIENT);

    useEffect(() => {
        setActiveLayerList(layerStatus.data?.allCartoLayers?.nodes?.filter((layer) => layer.isActive))
    }, [layerStatus.data]);

    useEffect(() => {
        if (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Error Message',
                detail: 'Map not found',
                life: 3000
            });
        }

        if (data) {
            setMap(data?.cartoStaticMapById);

            if (!data?.cartoStaticMapById) {
                navigate('/map')

                return;
            }

            let _layers = data?.cartoStaticMapById?.interface === 'ADMIN'
                ? data.cartoStaticMapById.cartoMapLayerAdminsByIdMap.nodes
                : data.cartoStaticMapById.cartoMapLayerClientsByIdMap.nodes

            _layers = formatLayerList(_layers)

            setLayers(_layers)
        }
    }, [id, error, data]);

    const formatLayerList = (layers) => layers.map((layer) => ({
        ...layer,
        canDuplicate: layer.__typename === 'CartoMapLayerAdmin' && layers.filter((_layer) => _layer.idLayer === layer.idLayer).length === 1
    }));

    if (loading) return (
        <div className="container mt-4 mx-auto">
            <div className="flex mb-4 align-items-start flex-column lg:justify-content-between lg:flex-row">
                <div>
                    <div className="font-medium text-3xl text-900">Maps</div>
                    <div className="flex align-items-center text-700 flex-wrap">
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-users mr-2"></i>
                            <span><Skeleton size="1rem"/></span>
                        </div>
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-globe mr-2"></i>
                            <span><Skeleton size="1rem"/></span>

                        </div>
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-globe mr-2"></i>
                            <span><Skeleton size="1rem"/></span>

                        </div>
                    </div>
                </div>
            </div>

            {EmptyTable(['Nom', 'Interface', 'Description'], 30)}
        </div>
    );

    const isLayerActive = (layer) => {
        return activeLayerList?.filter((_layer) => _layer.id === layer.idLayer).length > 0
    }

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const LayerTemplate = (data) => {
        return <div className="cursor-pointer" onClick={() => navigate('/layers/' + data.idLayer)}>
            <div className="border-circle w-2rem h-2rem inline-flex font-bold justify-content-center align-items-center text-sm mr-2">
                {data?.idLayer}
            </div>
            {data.cartoLayerByIdLayer.nameFr}

            {!isLayerActive(data) && (
                <span className="ml-2 bg-red-600 text-white border-round p-1">
                    <i className="fa fa-exclamation-triangle mr-1"></i>
                    Layer désactivé
                </span>
            )}
        </div>
    }

    const updateLayerAvailability = (layer) => {
        saveLayer({...layer, isChecked: !layer.isChecked})
    }

    const layerCheckedTemplate = (data) => {
        return <div>
            <InputSwitch checked={data.isChecked} onChange={(e) => updateLayerAvailability(data)} />
        </div>
    }

    const AccessAdminTemplate = (rowData) => {
        return rowData?.idTypeAdmin === "INTERNATIONAL"
            ? <Tag className="mr-2 text-white" style={{background: 'black'}} icon="pi pi-check" value="INTERNATIONAL"></Tag>
            : <Tag className="mr-2" icon="pi pi-check" style={{background: 'linear-gradient(90deg,blue 0%, white 50%, red 100%)', color:'black'}} value="FRANCE"></Tag>

    }

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <span className="p-input-icon-left">
                <i className="fa-duotone fa-search" />
                <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Rechercher..." />
            </span>
        );
    };

    const header = renderHeader();

    const duplicateLayer = (layer) => {
        const _layer = {
            ...layer,
            idTypeAdmin: layer.idTypeAdmin === 'FRANCE' ? 'INTERNATIONAL' : 'FRANCE',
        }

        addLayerToMapAdmin({
            variables: {
                cartoMapLayerAdmin: {
                    idLayer: _layer.idLayer,
                    idMap: Number(id),
                    idTypeAdmin: _layer.idTypeAdmin,
                    zoomMin: _layer.zoomMin,
                    zoomMax: _layer.zoomMax,
                    isChecked: _layer.isChecked,
                    pad: _layer.pad,
                }
            }
        }).then((response) => {
            setLayers(formatLayerList([...layers, _layer]))
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Layer Added', life: 6000 });
        });
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex justify-content-end">
                {rowData.canDuplicate && <Button icon="fa-duotone fa-layer-plus"
                                                 rounded
                                                 outlined
                                                 className="mr-2"
                                                 tooltip="Ajouter l'accès France/International" tooltipOptions={{ position: 'left' }}
                                                 onClick={() => duplicateLayer(rowData)} />}
                <Button icon="fa-duotone fa-pen-to-square"
                        rounded
                        outlined
                        className="mr-2"
                        onClick={() => editLayer(rowData)} />
                <Button icon="fa-duotone fa-trash"
                        rounded
                        outlined
                        severity="danger"
                        onClick={($event) => confirmDeleteLayer($event, rowData)} />
            </div>
        );
    };

    const editLayer = (layer) => {
        setSelectedLayer({
            layer: {
                idMap: Number(id),
                ...layer
            },
            isTypeAdmin: map.interface === 'ADMIN',
        });
    };

    const confirmDeleteLayer = (event, layer) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Vous êtes sûr de vouloir supprimer ce layer ?',
            icon: 'fa fa-exclamation-triangle',
            acceptClassName: 'p-button-danger',

            accept: () => {
                const request = layer.__typename === 'CartoMapLayerAdmin'
                    ? removeLayerAdmin({
                        variables: {
                            idLayer: layer.idLayer,
                            idMap: Number(id),
                            idTypeAdmin: layer.idTypeAdmin,
                        }
                    })
                    : removeLayerClient({
                        variables: {
                            idLayer: layer.idLayer,
                            idMap: Number(id),
                        }
                    });

                request.then((response) => {
                    setLayers(formatLayerList(layers.filter((_layer) => _layer.nodeId !== layer.nodeId)))
                    refetch()
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Layer supprimé', life: 6000 });
                });
            }
        });
    }

    const saveLayer = (layer) => {

        let _layers = [...layers];
        let _selectedLayer = { ...layer };

        const alreadyExist = _layers.findIndex((layer) => layer.idLayer === _selectedLayer.idLayer);

        if (alreadyExist !== -1) {
            const updateRequest = _selectedLayer.__typename === 'CartoMapLayerAdmin'
                ? updateLayerAdmin({
                    variables: {
                        idLayer: _selectedLayer.idLayer,
                        idMap: Number(id),
                        idTypeAdmin: _selectedLayer.idTypeAdmin,
                        cartoMapLayerAdminPatch: {
                            zoomMin: _selectedLayer.zoomMin,
                            zoomMax: _selectedLayer.zoomMax,
                            isChecked: _selectedLayer.isChecked,
                            pad: _selectedLayer.pad,
                        }
                    }
                })
                : updateLayerClient({
                    variables: {
                        idLayer: _selectedLayer.idLayer,
                        idMap: Number(id),
                        cartoMapLayerClientPatch: {
                            zoomMin: _selectedLayer.zoomMin,
                            zoomMax: _selectedLayer.zoomMax,
                            isChecked: _selectedLayer.isChecked,
                            pad: _selectedLayer.pad,
                        }
                    }
                })

            updateRequest.then((response) => {
                refetch()
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Layer mis à jour', life: 6000 });
            }).catch((error) => {
                console.log('[MapSingle] -> updateLayerAdmin -> error', error)
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue lors de la mise à jour du layer', life: 6000 });
            });
        } else {
            const createRequest = _selectedLayer.__typename === 'CartoMapLayerAdmin'
                ? addLayerToMapAdmin({
                        variables: {
                            cartoMapLayerAdmin: {
                                idLayer: _selectedLayer.idLayer,
                                idMap: Number(id),
                                idTypeAdmin: _selectedLayer.idTypeAdmin,
                                zoomMin: _selectedLayer.zoomMin,
                                zoomMax: _selectedLayer.zoomMax,
                                isChecked: _selectedLayer.isChecked,
                                pad: _selectedLayer.pad,
                            }
                        }
                    })
                : addLayerToMapClient({
                    variables: {
                        cartoMapLayerClient: {
                            idLayer: _selectedLayer.idLayer,
                            idMap: Number(id),
                            zoomMin: _selectedLayer.zoomMin,
                            zoomMax: _selectedLayer.zoomMax,
                            isChecked: _selectedLayer.isChecked,
                            pad: _selectedLayer.pad,
                        }
                    }
                })

            createRequest.then((response) => {
                _selectedLayer.nodeId = response.data?.createCartoMapLayerAdmin?.cartoMapLayerAdmin.nodeId;
                _layers.push(_selectedLayer);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: `Layer ajouté`, life: 6000 });
            }).catch((error) => {
                console.log('[MapSingle] -> (error) -> ', error)
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue lors de l\'ajout du layer', life: 6000 });
            });
        }

        setLayers(formatLayerList(_layers));
    };

    const deleteMap = (e) => {
        confirmPopup({
            target: e.currentTarget,
            message: 'Vous êtes sûr de vouloir supprimer cette map ?',
            icon: 'fa fa-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            focusOnShow: false,
            accept: () => {
                const request = removeMap({
                    variables: {
                        id: Number(id),
                    }
                });

                request.then((response) => {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Map Removed', life: 6000 });
                    navigate('/map')
                });
            }
        });
    }

    const addLayer = () => {
        setSelectedLayer({
            layer: {
                idMap: Number(id),
                idLayer: null,
                zoomMin: 0,
                zoomMax: 18,
            },
            isTypeAdmin: map.interface === 'ADMIN',
        });
    }

    const handleMutation = ((e) => {
        toast.current.show({
            severity: 'success',
            detail: e.type === 'add' ? 'Layer ajouté' : 'Layer mis à jour',
            life: 6000
        });

        refetch()
    })

    return (<>
        <Toast ref={toast} />
        <ConfirmPopup />
        <Tooltip />

        <div className="container mt-4 mx-auto">
            <div className="flex mb-4 align-items-start flex-column lg:justify-content-between lg:flex-row">
                <div>
                    <div className="font-medium text-3xl text-900">
                        Map > {map?.describe}
                    </div>
                    <div className="flex align-items-center text-700 flex-wrap">
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-users mr-2"></i>
                            <span>{layers?.length} Layers</span>
                        </div>
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-globe mr-2"></i>
                            <span></span>

                        </div>
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-globe mr-2"></i>
                            <span></span>

                        </div>
                    </div>

                </div>
                <div className="mt-3 lg:ml-2 gap-2">
                    <Button onClick={deleteMap} label="Supprimer la map" className="p-button-outlined p-button-danger mr-2" icon="fa-duotone fa-trash mr-2" />
                    <Button onClick={addLayer} label="Ajouter un layer" className="p-button-outlined mr-2" icon="fa-duotone fa-layer-plus mr-2" />
                </div>
            </div>

            <Card>
                <DataTable value={layers}
                           scrollable
                           scrollHeight="70vh"
                           header={header}
                           filters={filters}
                           stripedRows
                           sortField="idLayer"
                           globalFilterFields={['idLayer', 'cartoLayerByIdLayer.nameFr']}
                           onFilter={(e) => setFilters(e.filters)}
                           rowGroupMode="rowspan"
                           groupRowsBy="idLayer"
                           stateStorage="session"
                           stateKey="dt-map-layer"
                           emptyMessage="Aucun layer trouvé."
                           tableStyle={{ minWidth: '50rem' }}>
                    <Column field="idLayer" header="Layer" body={LayerTemplate} sortable></Column>
                    <Column field="isChecked" header="Actif par default" body={layerCheckedTemplate} sortable></Column>
                    <Column field="zoomMin" header="Zoom min"  sortable></Column>
                    <Column field="zoomMax" header="Zoom max"  sortable></Column>
                    {map?.interface === 'ADMIN' && <Column field="idTypeAdmin" header="Accès Admin" body={AccessAdminTemplate} sortable></Column>}
                    <Column body={actionBodyTemplate} exportable={false}></Column>
                </DataTable>
            </Card>
        </div>

        <MapModelAddLayer el={selectedLayer} onMutation={(e) => handleMutation(e)}></MapModelAddLayer>
    </>);
}

export default MapSingle;
