import {Dialog} from "primereact/dialog";
import {
    ADD_LAYER_TO_MAP_ADMIN,
    ADD_LAYER_TO_MAP_CLIENT,
    GET_MAPS,
    UPDATE_MAP_LAYER_ADMIN, UPDATE_MAP_LAYER_CLIENT
} from "../maps/MapQuery";
import {useMutation, useQuery} from "@apollo/client";
import React, {useEffect, useRef, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {Button} from "primereact/button";
import {InputSwitch} from "primereact/inputswitch";
import {classNames} from "primereact/utils";
import {SelectPad} from "../../ui/SelectPad";
import {InputNumber} from "primereact/inputnumber";
import {RadioButton} from "primereact/radiobutton";
import {Messages} from "primereact/messages";
import {SelectMap} from "../../ui/SelectMap";
import {Tag} from "primereact/tag";

export const LayerLinkToMap = ({data, onClose}) => {

    const {
        control,
        formState: {errors },
        watch,
        setValue,
        handleSubmit
    } = useForm({
        defaultValues: {
            idMap: data?.map?.idMap,
            idLayer: data?.layer?.idLayer,
            pad: data?.layer?.pad,
            selectedTypeInterface: data?.map?.selectedTypeInterface,
            idTypeAdmin: data?.map?.idTypeAdmin,
            selectedTypeAdmin: [],
            zoomMin: 0,
            zoomMax: 18,
            isChecked: data?.map?.isChecked,
        }
    });

    const msgs = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isNew, setIsNew] = useState(!data?.idLayer);
    const [visible, setVisible] = useState(false);
    const { data : maps } = useQuery(GET_MAPS);
    const selectedTypeInterface = watch('selectedTypeInterface')



    const [addLayerToMapAdmin] = useMutation(ADD_LAYER_TO_MAP_ADMIN, {
        onError: (error) => {
            console.log('[MapModelAddLayer] -> addLayerToMapAdmin(error) -> ', error)
        }
    });
    const [addLayerToMapClient] = useMutation(ADD_LAYER_TO_MAP_CLIENT, {
        onError: (error) => {
            console.log('[MapModelAddLayer] -> addLayerToMapClient(error) -> ', error)
        }
    });

    const [updateLayerAdmin] = useMutation(UPDATE_MAP_LAYER_ADMIN);
    const [updateLayerClient] = useMutation(UPDATE_MAP_LAYER_CLIENT);

    useEffect(() => {

        if (data) {
            setIsLoading(false);

            setIsNew(!data?.layer.idLayer)

            // set form values
            setValue('idMap', data?.map?.idMap);
            
            setValue('idLayer', data?.layer.idLayer);
            setValue('pad', data?.map?.pad);
            setValue('idTypeAdmin', data?.map?.idTypeAdmin);
            setValue('isChecked', data?.map?.isChecked);
            setValue('zoomMin', data?.map?.zoomMin);
            setValue('zoomMax', data?.map?.zoomMax);
            setValue('selectedTypeInterface', data?.map?.selectedTypeInterface || 'CLIENT');

            setVisible(true)


        }
    }, [data]);

    const upsertLayer = (form) => {
        setIsLoading(true);

        let _selectedLayer = { ...form };
        
        if (data.type === 'create' || data.type === 'copy') {
            const createRequest = selectedTypeInterface === 'ADMIN'
                ? addLayerToMapAdmin({
                    variables: {
                        cartoMapLayerAdmin: {
                            idLayer: data?.layer.id,
                            idMap: _selectedLayer.idMap,
                            idTypeAdmin: _selectedLayer.idTypeAdmin,
                            zoomMin: _selectedLayer.zoomMin,
                            zoomMax: _selectedLayer.zoomMax,
                            isChecked: _selectedLayer.isChecked,
                            pad: _selectedLayer.pad,
                        }
                    }
                })
                : addLayerToMapClient({
                    variables: {
                        cartoMapLayerClient: {
                            idLayer: data?.layer.id,
                            idMap: _selectedLayer.idMap,
                            zoomMin: _selectedLayer.zoomMin,
                            zoomMax: _selectedLayer.zoomMax,
                            isChecked: _selectedLayer.isChecked,
                            pad: _selectedLayer.pad,
                        }
                    }
                })

            createRequest.then((response) => {
                if (!!response.data) {
                    setVisible(false);
                    onClose();
                } else {
                    msgs.current.show({
                        severity: 'error',
                        sticky: true,
                        content: errorMessages(response.errors)
                    });
                }
                setIsLoading(false)
            });
        } else {
            const updateRequest = selectedTypeInterface === 'ADMIN'
                ? updateLayerAdmin({
                    variables: {
                        idLayer: data?.layer.id,
                        idMap: _selectedLayer.idMap,
                        idTypeAdmin: _selectedLayer.idTypeAdmin,
                        cartoMapLayerAdminPatch: {
                            zoomMin: _selectedLayer.zoomMin,
                            zoomMax: _selectedLayer.zoomMax,
                            isChecked: _selectedLayer.isChecked,
                            pad: _selectedLayer.pad,
                        }
                    }
                })
                : updateLayerClient({
                    variables: {
                        idLayer: data?.layer.id,
                        idMap: _selectedLayer.idMap,
                        cartoMapLayerClientPatch: {
                            zoomMin: _selectedLayer.zoomMin,
                            zoomMax: _selectedLayer.zoomMax,
                            isChecked: _selectedLayer.isChecked,
                            pad: _selectedLayer.pad,
                        }
                    }
                })

            updateRequest.then((response) => {
                if (!!response.data) {
                    setVisible(false);
                    onClose();
                } else {
                    msgs.current.show({
                        severity: 'error',
                        sticky: true,
                        content: errorMessages(response.errors)
                    });
                }

                setIsLoading(false)
            });
        }
    };

    const errorMessages = (errors) => {
        return (
            <>
                <div className="flex flex-column gap-3">
                    <div className="flex align-items-center gap-3 font-bold">
                        <i className="fa fa-exclamation-triangle" style={{ fontSize: '2rem' }} />
                        <div>
                            Erreur
                        </div>
                    </div>
                    <div>
                        {errors?.message}
                    </div>
                </div>
            </>
        )
    }


    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    const footer = (
        <div>
            <Button disabled={isLoading} severity={'success'} label={data?.type === 'edit' ? 'Modifier' : 'Créer'} icon="fa fa-check" onClick={handleSubmit(upsertLayer)} />
            <Button disabled={isLoading} outlined label="Annuler" onClick={() => setVisible(false)} />
        </div>
    );

    const header = (
        <div className="flex gap-3 align-items-center">
            <label className="font-bold">
                {data?.type === 'edit' && 'Modifier une affectation'}
                {data?.type === 'create' && 'Ajouter une affectation'}
                {data?.type === 'copy' && 'Dupliquer une affectation'}
            </label>
        </div>
    );

    if (!data?.layer) {
        return null;
    }

    return (
        <>
            <Dialog visible={visible} style={{width: '450px'}} header={header} modal className="p-fluid" footer={footer}
                    onHide={() => setVisible(false)}>
                <div className="field">
                    <label htmlFor="isChecked" className="font-bold">Actif par defaut</label>
                    <div>
                        <Controller
                            name="isChecked"
                            control={control}
                            render={({ field, fieldState, onChange }) => (
                                <InputSwitch
                                    checked={field.value}
                                    id={field.name}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                    </div>
                </div>


                {data?.type !== 'edit' && (
                    <>
                        <div className="field">
                            <label htmlFor="describeEn" className="font-bold">Interface</label>
                            <div>
                                <Controller
                                    name="selectedTypeInterface"
                                    control={control}
                                    rules={{required: 'Champ requis'}}
                                    render={({field, fieldState, onChange}) => (
                                        <>
                                            <div className={classNames('flex justify-space-between', {
                                                'border border-1 border-orange-500 p-2 border-round-sm pointer-events-none': data?.type === 'edit'
                                            })}>
                                                <div className="flex align-items-center">
                                                    <RadioButton inputId="CLIENT" {...field} inputRef={field.ref}
                                                                 value="CLIENT"
                                                                 checked={field.value === 'CLIENT'}/>
                                                    <label htmlFor="CLIENT" className="ml-1 mr-3">
                                                        <Tag style={{background: '#00ade5'}}
                                                             value={'CLIENT'}>
                                                        </Tag>
                                                    </label>

                                                    <RadioButton inputId="ADMIN" {...field} value="ADMIN"
                                                                 checked={field.value === 'ADMIN'}/>
                                                    <label htmlFor="ADMIN" className="ml-1 mr-3">
                                                        <Tag style={{background: '#f0a'}}
                                                             value={'ADMIN'}>
                                                        </Tag>
                                                    </label>
                                                </div>
                                            </div>
                                            {getFormErrorMessage(field.name)}
                                        </>
                                    )}
                                />
                            </div>
                        </div>

                        {selectedTypeInterface === 'ADMIN' && (
                            <div className="field">
                                <label htmlFor="idTypeAdmin" className="font-bold">Type d'admin</label>
                                <Controller
                                    name="idTypeAdmin"
                                    control={control}
                                    rules={{required: 'Champ requis'}}
                                    render={({field}) => (
                                        <>
                                            <div className="flex justify-space-between">
                                                <div className="flex align-items-center">
                                                    <RadioButton inputId="FRANCE" {...field} inputRef={field.ref}
                                                                 value="FRANCE"
                                                                 checked={field.value === 'FRANCE'}/>
                                                    <label htmlFor="FRANCE" className="ml-1 mr-3">
                                                        <Tag className="mr-2" icon="pi pi-check" style={{
                                                            background: 'linear-gradient(90deg,blue 0%, white 50%, red 100%)',
                                                            color: 'black'
                                                        }} value="FRANCE"></Tag>
                                                    </label>

                                                    <RadioButton inputId="INTERNATIONAL" {...field}
                                                                 value="INTERNATIONAL"
                                                                 checked={field.value === 'INTERNATIONAL'}/>
                                                    <label htmlFor="INTERNATIONAL" className="ml-1 mr-3">
                                                        <Tag className="mr-2 text-white" style={{background: 'black'}}
                                                             icon="pi pi-check" value="INTERNATIONAL"></Tag>
                                                    </label>
                                                </div>
                                            </div>
                                            {getFormErrorMessage(field.name)}
                                        </>
                                    )}
                                />
                            </div>
                        )}

                        <div className="field">
                            <label htmlFor="idMap" className="font-bold">
                                Map ( {selectedTypeInterface} )
                            </label>
                            <Controller
                                name="idMap"
                                control={control}
                                rules={{required: 'Champ requis'}}
                                render={({field, fieldState, onChange}) => (
                                    <>
                                        <SelectMap control={control}
                                                   single={true}
                                                   id={field.name}
                                                   filterMap={selectedTypeInterface}
                                                   name={field.name}
                                                   value={field.value}
                                                   className={classNames({'p-invalid': fieldState.invalid})}
                                                   onChange={field.onChange}

                                        />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}/>
                        </div>
                    </>
                )}

                <div className="field">
                    <label htmlFor="description" className="font-bold">
                        Pad ( emplacement )
                    </label>
                    <Controller
                        name="pad"
                        control={control}
                        rules={{required: 'Champ requis'}}
                        render={({field, fieldState, onChange}) => (
                            <>
                                <SelectPad control={control}
                                           id={field.name}
                                           name={field.name}
                                           mapInterface={selectedTypeInterface}
                                           value={field.value}
                                           className={classNames({'p-invalid': fieldState.invalid})}
                                           onChange={field.onChange}

                                />
                                {getFormErrorMessage(field.name)}
                            </>
                        )}/>
                </div>

                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="zoomMin" className="font-bold">
                            Zoom MIN
                        </label>

                        <Controller
                            name="zoomMin"
                            control={control}
                            render={({field, fieldState, onChange}) => (
                                <InputNumber id="zoomMin" value={field.value} onChange={(e) => field.onChange(e.value)}
                                             showButtons buttonLayout="horizontal" step={1}
                                             incrementButtonIcon="fa fa-plus"
                                             decrementButtonIcon="fa fa-minus"
                                             min={0} max={18}/>
                            )}
                        />
                    </div>
                    <div className="field col">
                        <label htmlFor="zoomMax" className="font-bold">
                            Zoom MAX
                        </label>
                        <Controller
                            name="zoomMax"
                            control={control}
                            render={({field, fieldState, onChange}) => (
                                <InputNumber id="zoomMax" value={field.value} onChange={(e) => field.onChange(e.value)}
                                             showButtons buttonLayout="horizontal" step={1}
                                             incrementButtonIcon="fa fa-plus"
                                             decrementButtonIcon="fa fa-minus"
                                             min={0} max={18}/>
                            )}
                        />
                    </div>
                </div>

                <Messages ref={msgs}/>
            </Dialog>
        </>
    );
}
