import React, { useEffect, useState } from 'react';
import {gql, useQuery} from "@apollo/client";
import {MultiSelect} from "primereact/multiselect";
import {Skeleton} from "primereact/skeleton";
import {Dropdown} from "primereact/dropdown";

const GET_USERS = gql`
    query GetGroups {
        allUsersUsers {
            nodes {
                idUser
                nameUser
            }
        }
    }
`;

export const SelectUsers = ({ single, name, id, label, value, disabled, onChange, ...props }) => {
    const [selectAll, setSelectAll] = useState(false);
    const { data } = useQuery(GET_USERS);

    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (data) {
            const _users = data.allUsersUsers.nodes.map(user => {
                return {
                    ...user,
                    value: user.idUser,
                    name: user.nameUser,
                    label: `${user.nameUser} - ${user.idUser}`
                }
            })

            setUsers(_users);
        }
    }, [data]);

    const _onChange = (e) => {
        onChange(e.value);
    }

    if (!users.length) {
        return <Skeleton width="100%" height={'44px'}></Skeleton>
    }

    if (single) {
        return <Dropdown
            id={id}
            name={name}
            value={value}
            options={users}
            virtualScrollerOptions={{ itemSize: 30 }}
            onChange={_onChange}
            placeholder={"Sélectionner un utilisateur"}
            filter
            filterBy={"label"}
            disabled={disabled}
            className={"w-full"}
        />
    }

    return (
        <MultiSelect id={id}
                     name={name}
                     value={value}
                     filter
                     selectAll={selectAll}
                     onSelectAll={(e) => {
                         setSelectAll(!e.checked);
                     }}
                     onChange={(e) => {
                         setSelectAll(e.value.length === users.length);
                         _onChange(e);
                     }}
                     disabled={disabled}
                     filterBy={"label"}
                     className={"w-full"}
                     display={"chip"}
                     maxSelectedLabels={30}
                     virtualScrollerOptions={{ itemSize: 43 }}
                     options={users}
                     optionLabel="label"
                     placeholder="Sélectionner un ou plusieurs utilisateurs"
        />
    )

}