import {gql} from "@apollo/client";


export const GET_LAYERS = gql`
    query GetLayersWithRules {
        allCartoLayers {
            nodes {
                id
                isActive
                idBbox
                type
                nameFr
                cartoLayerRulesMappingsByIdLayer {
                    nodes {
                        cartoStaticLayerRuleByIdRule {
                            nameRule
                            idRule
                        }
                    }
                }
            }
        }
    }
`;

export const GET_LAYERS_IDS = gql`
    query GetLayersWithRules {
        allCartoLayers {
            nodes {
                id
            }
        }
    }
`;

export const GET_LAYER = gql`
    query GetLayerFullData($id: Int!) {
        cartoLayerById(id: $id) {
            describeEn
            describeFr
            id
            idBbox
            isActive
            legendUrl
            nameEn
            opacity
            nameFr
            order
            technicalName
            zoomMinLimit
            type
            cartoLayerRulesMappingsByIdLayer {
                nodes {
                    cartoStaticLayerRuleByIdRule {
                        cartoLayerRulesUsersByIdRule {
                            nodes {
                                idUser
                            }
                        }
                        cartoLayerRulesGroupsByIdRule {
                            nodes {
                                idGroup
                            }
                        }
                        nameRule
                        idRule
                    }
                }
            }
            cartoLayerKmlByIdLayer {
                url
            }
            cartoLayerImageByIdLayer {
                live
                timeLimit
                animate
                url
            }
            cartoLayerPoiByIdLayer {
                poiCategory
            }
            cartoLayerWmByIdLayer {
                baseurl
                animate
                crs
                format
                idLayer
                layers
                tilesize
                styles
                timeLimit
                tms
                uppercase
                version
            }
            cartoMapLayerClientsByIdLayer {
                nodes {
                    cartoStaticMapByIdMap {
                        describe
                    }
                    idMap
                    isChecked
                    pad
                    zoomMax
                    zoomMin
                }
            }
            cartoMapLayerAdminsByIdLayer {
                nodes {
                    cartoStaticMapByIdMap {
                        describe
                    }
                    idMap
                    idTypeAdmin
                    isChecked
                    pad
                    zoomMax
                    zoomMin
                }
            }
            cartoMapLayerClientOverrideUsersByIdLayer {
                nodes {
                    idLayer
                    idMap
                    idUser
                }
            }
            cartoMapLayerClientOverrideGroupsByIdLayer {
                nodes {
                    idMap
                    idLayer
                    idGroup
                }
            }
        }
    }
`;

export const GET_LAYER_MAPS = gql`
    query GetLayerMapFullData($id: Int!) {
        cartoLayerById(id: $id) {
            cartoMapLayerClientsByIdLayer {
                nodes {
                    idMap
                    isChecked
                    pad
                    zoomMax
                    zoomMin
                }
            }
            cartoMapLayerAdminsByIdLayer {
                nodes {
                    idMap
                    idTypeAdmin
                    isChecked
                    pad
                    zoomMax
                    zoomMin
                }
            }
        }
    }
`;


    export const UPDATE_CARTO_LAYER_BY_ID = gql`
    mutation UpdateCartoLayerById($id: Int!, $cartoLayerPatch: CartoLayerPatch!) {
        updateCartoLayerById(input: {
            id: $id,
            cartoLayerPatch: $cartoLayerPatch
        }) {
            cartoLayer {
                zoomMinLimit
                type
                technicalName
                order
                opacity
                nameEn
                nameFr
                legendUrl
                isActive
                idBbox
                describeFr
                describeEn
                attribution
            }
        }
    }
`;

export const CREATE_CARTO_LAYER = gql`
    mutation CreateCartoLayer($cartoLayer: CartoLayerInput!) {
        createCartoLayer(input: {
            cartoLayer: $cartoLayer
        }) {
            cartoLayer {
                nodeId,
                id,
            }
        }
    }
`;

export const DELETE_CARTO_LAYER_BY_ID = gql`
    mutation DeleteCartoLayerById($id: Int!) {
        deleteCartoLayerById(input: {
            id: $id
        }) {
            cartoLayer {
                nodeId
            }
        }
    }
`;

export const UPDATE_KML_BY_LAYER_ID = gql`
    mutation UpdateCartoLayerKmlById($idLayer: Int!, $cartoLayerKmlPatch: CartoLayerKmlPatch!) {
        updateCartoLayerKmlByIdLayer(input: {
            idLayer: $idLayer,
            cartoLayerKmlPatch: $cartoLayerKmlPatch}
        ) {
            cartoLayerKml {
                url
                nodeId
            }
        }
    }
`;

export const CREATE_KML_BY_LAYER_ID = gql`
    mutation CreateKml($cartoLayerKml: CartoLayerKmlInput!) {
        createCartoLayerKml(input: {
            cartoLayerKml: $cartoLayerKml
        }) {
            cartoLayerKml {
                url
                nodeId
            }
        }
    }
`;

export const DELETE_KML_BY_LAYER_ID = gql`
    mutation DeleteCartoLayerKmlById($idLayer: Int!) {
        deleteCartoLayerKmlByIdLayer(input: {
            idLayer: $idLayer
        }) {
            deletedCartoLayerKmlId
        }
    }
`;

export const UPDATE_IMAGE_BY_LAYER_ID = gql`
    mutation UpdateCartoLayerImageById($idLayer: Int!, $cartoLayerImagePatch: CartoLayerImagePatch!) {
        updateCartoLayerImageByIdLayer(input: {
            idLayer: $idLayer,
            cartoLayerImagePatch: $cartoLayerImagePatch}
        ) {
            cartoLayerImage {
                url
                nodeId
            }
        }
    }
`;

export const CREATE_IMAGE_BY_LAYER_ID = gql`
    mutation CreateImage($cartoLayerImage: CartoLayerImageInput!) {
        createCartoLayerImage(input: {
            cartoLayerImage: $cartoLayerImage
        }) {
            cartoLayerImage {
                url
                nodeId
            }
        }
    }
`;

export const UPDATE_POI_BY_LAYER_ID = gql`
    mutation UpdateCartoLayerPoiById($idLayer: Int!, $cartoLayerPoiPatch: CartoLayerPoiPatch!) {
        updateCartoLayerPoiByIdLayer(input: {
            idLayer: $idLayer,
            cartoLayerPoiPatch: $cartoLayerPoiPatch}
        ) {
            cartoLayerPoi {
                poiCategory
                nodeId
            }
        }
    }
`;

export const CREATE_POI_BY_LAYER_ID = gql`
    mutation CreatePoi($cartoLayerPoi: CartoLayerPoiInput!) {
        createCartoLayerPoi(input: {
            cartoLayerPoi: $cartoLayerPoi
        }) {
            cartoLayerPoi {
                poiCategory
                nodeId
            }
        }
    }
`;

export const DELETE_POI_BY_LAYER_ID = gql`
    mutation DeleteCartoLayerPoiById($idLayer: Int!) {
        deleteCartoLayerPoiByIdLayer(input: {
            idLayer: $idLayer
        }) {
            cartoLayerPoi {
                nodeId
            }
        }
    }
`;

export const UPDATE_WMS_BY_LAYER_ID = gql`
    mutation UpdateCartoLayerWmById($idLayer: Int!, $cartoLayerWmPatch: CartoLayerWmPatch!) {
        updateCartoLayerWmByIdLayer(input: {
            idLayer: $idLayer,
            cartoLayerWmPatch: $cartoLayerWmPatch}
        ) {
            cartoLayerWm {
                version
                uppercase
                tms
                timeLimit
                tilesize
                styles
                baseurl
                animate
                crs
                format
            }
        }
    }
`;

export const CREATE_WMS_BY_LAYER_ID = gql`
    mutation CreateWms($cartoLayerWm: CartoLayerWmInput!) {
        createCartoLayerWm(input: {
            cartoLayerWm: $cartoLayerWm
        }) {
            cartoLayerWm {
                version
                uppercase
                tms
                timeLimit
                tilesize
                styles
                baseurl
                animate
                crs
                format
                nodeId
            }
        }
    }
`;

export const DELETE_WMS_BY_LAYER_ID = gql`
    mutation DeleteCartoLayerWmById($idLayer: Int!) {
        deleteCartoLayerWmByIdLayer(input: {
            idLayer: $idLayer
        }) {
            cartoLayerWm {
                version
            }
        }
    }
`;


export const DELETE_IMAGE_BY_LAYER_ID = gql`
    mutation DeleteLayerImg($idLayer: Int!) {
        deleteCartoLayerImageByIdLayer(input: {
            idLayer: $idLayer
        }) {
            deletedCartoLayerImageId
        }
    }
`;

export const DELETE_LAYER_ASSOCIATED_MAP = (idLayer, listMapAdmin, listMapClient) => {
    let baseMutation = ``;

    if (listMapAdmin?.length > 0) {
        listMapAdmin.forEach((map, idx) => {
            baseMutation += `
            deleteAdminMapAssoc_${idx}: deleteCartoMapLayerAdminByIdMapAndIdLayerAndIdTypeAdmin(input: {
                idMap: ${map.idMap},
                idLayer: ${idLayer},
                idTypeAdmin: ${map.idTypeAdmin},
            }) {
                clientMutationId
            }
            `;
        });
    }

    if (listMapClient?.length > 0) {
        listMapClient.forEach((map, idx) => {
            baseMutation += `
            deleteClientMapAssoc_${idx}: deleteCartoMapLayerClientByIdMapAndIdLayer(input: {
                idMap: ${map.idMap},
                idLayer: ${idLayer},
            }) {
                clientMutationId
            }
            `;
        });
    }

    return `mutation RemoveLayerMapAssociation {
            ${baseMutation}
        }
    `;
}


export const DELETE_LAYER_ASSOCIATED_OVERRIDE = (idLayer, listOverrideGroup, listOverrrideUser) => {
    let baseMutation = ``;

    if (listOverrideGroup?.length > 0) {
        listOverrideGroup.forEach((map, idx) => {
            baseMutation += `
            deleteAdminOverrideAssoc_${idx}: deleteCartoMapLayerClientOverrideGroupByIdGroupAndIdMapAndIdLayer(input: {
                idMap: ${map.idMap},
                idLayer: ${idLayer},
                idGroup: ${map.idGroup},
            }) {
                clientMutationId
            }
            `;
        });
    }

    if (listOverrrideUser?.length > 0) {
        listOverrrideUser.forEach((map, idx) => {
            baseMutation += `
            deleteClientOverrideAssoc_${idx}: deleteCartoMapLayerClientOverrideUserByIdUserAndIdMapAndIdLayer(input: {
                idMap: ${map.idMap},
                idLayer: ${idLayer},
                idUser: ${map.idUser},
            }) {
                clientMutationId
            }
            `;
        });
    }

    return `mutation RemoveLayerMapClientOverride {
            ${baseMutation}
        }
    `;
}

export const DUPLICATE_LAYER_MAPS = (idLayer, listMapAdmin, listMapClient) => {
    let baseMutation = ``;

    if (listMapAdmin?.length > 0) {
        listMapAdmin.forEach((map, idx) => {
            baseMutation += `
            duplicateMapAdminLayer_${idx}: createCartoMapLayerAdmin(input: {cartoMapLayerAdmin: {
                idLayer: ${idLayer}  
                idMap: ${map.idMap} 
                idTypeAdmin: ${map.idTypeAdmin}  
                isChecked: ${map.isChecked}  
                pad: ${map.pad}  
                zoomMax: ${map.zoomMax}  
                zoomMin: ${map.zoomMin} 
            }}) { clientMutationId }
            `;
        });
    }

    if (listMapClient?.length > 0) {
        listMapClient.forEach((map, idx) => {
            baseMutation += `
            duplicateMapClientLayer_${idx}: createCartoMapLayerClient(input: {cartoMapLayerClient: {
                idLayer: ${idLayer}
                idMap: ${map.idMap} 
                isChecked: ${map.isChecked}  
                pad: ${map.pad}  
                zoomMax: ${map.zoomMax}  
                zoomMin: ${map.zoomMin} 
            }}) { clientMutationId }
            `;
        });
    }

    return `mutation duplicateLayerMaps {
            ${baseMutation}
        }
    `;
}

export const GET_LAYER_OVERRIDE = gql`
    query GetLayerOverride($id: Int!) {
      cartoLayerById(id: $id) {
        cartoMapLayerClientOverrideUsersByIdLayer {
          nodes {
            zoomMin
            zoomMax
            isChecked
            idUser
            idMap
            idLayer
          }
        }
        cartoMapLayerClientOverrideGroupsByIdLayer {
          nodes {
            zoomMin
            zoomMax
            isChecked
            idMap
            idLayer
            idGroup
          }
        }
      }
    }
`;

export const DUPLICATE_LAYER_OVERRIDE = (idLayer, listOverrideGroup, listOverrideUser) => {
    let baseMutation = ``;

    if (listOverrideGroup?.length > 0) {
        listOverrideGroup.forEach((map, idx) => {
            baseMutation += `
            duplicateGroupOverride_${idx}: createCartoMapLayerClientOverrideGroup(input: {cartoMapLayerClientOverrideGroup: {
                idLayer: ${map.idLayer}  
                idMap: ${map.idMap} 
                isChecked: ${map.isChecked}  
                pad: ${map.pad}  
                zoomMax: ${map.zoomMax}  
                zoomMin: ${map.zoomMin} 
            }}) { clientMutationId }
            `;
        });
    }

    if (listOverrideUser?.length > 0) {
        listOverrideUser.forEach((map, idx) => {
            baseMutation += `
            duplicateUserOverride_${idx}: createCartoMapLayerClientOverrideUser(input: {cartoMapLayerClientOverrideUser: {
                idLayer: ${map.idLayer}  
                idMap: ${map.idMap} 
                isChecked: ${map.isChecked}  
                pad: ${map.pad}  
                zoomMax: ${map.zoomMax}  
                zoomMin: ${map.zoomMin} 
            }}) { clientMutationId }
            `;
        });
    }

    return `mutation duplicateLayerOverride {
            ${baseMutation}
        }
    `;
}
