import { Button } from 'primereact/button';
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {FilterMatchMode} from "primereact/api";
import {InputText} from "primereact/inputtext";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import { useQuery } from '@apollo/client';
import { Skeleton } from 'primereact/skeleton';
import EmptyTable from "../../ui/EmptyTable";
import {Card} from "primereact/card";
import {GET_RULES} from "./RuleQuery";

function RuleList() {

    let navigate = useNavigate();

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const [rules, setRules] = React.useState([]);
    const [selectedRule, setSelectedRule] = React.useState(null);

    const { loading, error, data } = useQuery(GET_RULES);

    useEffect(() => {
        if (data) {
            setRules(formatRules(data))
        }
    }, [loading, error, data]);


    const formatRules = (data) => {
        return data.allCartoStaticLayerRules.nodes.map((rule) => {
            return {
                ...rule,
                users: data.allCartoLayerRulesUsers.nodes
                    .filter((userRule) => userRule.idRule === rule.idRule)
                    .map((userRule) => ({
                        idUser: userRule.idUser,
                        nameUser: userRule.usersUserByIdUser.nameUser,
                    })),
                groups: data.allCartoLayerRulesGroups.nodes
                    .filter((groupRule) => groupRule.idRule === rule.idRule)
                    .map((groupRule) => ({
                        idGroup: groupRule.idGroup,
                        nameGroup: groupRule.usersStaticGroupByIdGroup.nameGroup,
                    })),
                layers: data.allCartoLayerRulesMappings.nodes
                    .filter((layerRule) => layerRule.idRule === rule.idRule)
                    .map((layerRule) => ({
                        idLayer: layerRule.idLayer,
                        name: layerRule.cartoLayerByIdLayer.nameFr,
                    })),
            }
        })
    }

    if (loading) return (
        <div className="container mt-4 mx-auto">
            <div className="flex mb-4 align-items-start flex-column lg:justify-content-between lg:flex-row">
                <div>
                    <div className="font-medium text-3xl text-900">Rules</div>
                    <div className="flex align-items-center text-700 flex-wrap">
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-users mr-2"></i>
                            <span><Skeleton size="1rem"/></span>
                        </div>
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-globe mr-2"></i>
                            <span><Skeleton size="1rem"/></span>

                        </div>
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-globe mr-2"></i>
                            <span><Skeleton size="1rem"/></span>
                        </div>
                    </div>
                </div>
            </div>

            {EmptyTable(['Nom', 'Interface', 'Description'], 30)}
        </div>

    );


    const columnCountTemplate = (rowData, alias) => {
        return rowData[alias]?.length || 0;
    }

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <span className="p-input-icon-left">
                <i className="fa-duotone fa-search" />
                <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
            </span>
        );
    };

    const header = renderHeader();

    const goToRule = (layer) => {
        setSelectedRule(layer)
        navigate(`/rules/${layer.idRule}`)
    }

    return (
        <div className="container mt-4 mx-auto">
            <div className="flex mb-4 align-items-start flex-column lg:justify-content-between lg:flex-row">
                <div>
                    <div className="font-medium text-3xl text-900">Rules</div>
                    <div className="flex align-items-center text-700 flex-wrap">
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-users mr-2"></i>
                            <span>{rules?.length} Rules</span>
                        </div>
                    </div>
                </div>
                <div className="mt-3 lg:ml-2">
                    <Button onClick={() => navigate('new')} label="Ajouter une rule" className="p-button-outlined mr-2" icon="fa-duotone fa-layer-plus mr-2" />
                </div>
            </div>

            <Card>
                <DataTable value={rules}
                           scrollable
                           scrollHeight="70vh"
                           header={header}
                           filters={filters}
                           onFilter={(e) => setFilters(e.filters)}
                           selection={selectedRule}
                           selectionMode={'single'}
                           onRowSelect={(e) => goToRule(e.data)}
                           stateStorage="session"
                           stateKey="ruleList"
                           emptyMessage="Aucun layer trouvé."
                           tableStyle={{ minWidth: '50rem' }}>
                    <Column field="nameRule" header="Nom" sortable></Column>
                    <Column field="layers" header="Layers" body={(a) => columnCountTemplate(a, 'layers')}  sortable></Column>
                    <Column field="users" header="Usager" body={(a) => columnCountTemplate(a, 'users')}  sortable></Column>
                    <Column field="groups" header="Group" body={(a) => columnCountTemplate(a, 'groups')}  sortable></Column>
                </DataTable>
            </Card>
        </div>
    );
}

export default RuleList;
