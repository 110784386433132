import React, { useEffect, useState } from 'react';
import {gql, useQuery} from "@apollo/client";
import {MultiSelect} from "primereact/multiselect";
import {Skeleton} from "primereact/skeleton";
import {Dropdown} from "primereact/dropdown";
import {GET_MAPS} from "../page/maps/MapQuery";

export const SelectMap = ({ single, disabled, name, id, className, label, value, filterMap, onChange, ...props }) => {

    const { data } = useQuery(GET_MAPS);

    const [maps, setMaps] = useState([]);
    const [filteredMaps, setFilteredMaps] = useState([]);

    useEffect(() => {
        if (data) {
            const _map = data.allCartoStaticMaps.nodes
                .filter(map => {
                    return filterMap ? map.interface === filterMap : true;
                })
                .map(layer => {
                return {
                    ...layer,
                    value: layer.id,
                    name: `${layer.id} - ${layer.describe}`
                }
            })

            setMaps(_map);
            setFilteredMaps(_map);
        }
    }, [data, filterMap]);

    const _onChange = (e) => {
        onChange(e.value);
    }

    if (!maps.length) {
        return <Skeleton width="100%" height={'44px'}></Skeleton>
    }

    if (single) {
        return (
            <Dropdown id={id}
                      name={name}
                      value={value}
                      disabled={!maps.length || disabled}
                      optionValue={"id"}
                      optionLabel={"name"}
                      filter={true}
                      filterBy={"name"}
                      className={"w-full"}
                      placeholder={"Sélectionner une map"}
                      onChange={(e) => onChange(e.value)}
                      options={filteredMaps}
            />
        )
    }

    return (
        <MultiSelect id={id}
                     name={name}
                     value={value}
                     filter
                     selectionLimit={single ? 1 : null}
                     disabled={!maps.length || disabled}
                     filterBy={"name"}
                     display={"chip"}
                     className={"w-full"}
                     virtualScrollerOptions={{ itemSize: 43 }}
                     options={maps}
                     placeholder={"Sélectionner un ou plusieurs maps"}
                     optionLabel="name"
                     onChange={_onChange}
        />
    )

}
