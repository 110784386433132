import {Controller, useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {InputTextarea} from "primereact/inputtextarea";
import React, {useEffect, useRef, useState} from "react";
import {SelectButton} from "primereact/selectbutton";
import { InputSwitch } from 'primereact/inputswitch';
import { Divider } from 'primereact/divider';
import {Tag} from "primereact/tag";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
    CREATE_CARTO_LAYER,
    CREATE_IMAGE_BY_LAYER_ID,
    CREATE_KML_BY_LAYER_ID,
    CREATE_POI_BY_LAYER_ID,
    CREATE_WMS_BY_LAYER_ID,
    DELETE_CARTO_LAYER_BY_ID,
    DELETE_IMAGE_BY_LAYER_ID,
    DELETE_KML_BY_LAYER_ID,
    DELETE_LAYER_ASSOCIATED_MAP, DELETE_LAYER_ASSOCIATED_OVERRIDE,
    DELETE_POI_BY_LAYER_ID,
    DELETE_WMS_BY_LAYER_ID, DUPLICATE_LAYER_MAPS, DUPLICATE_LAYER_OVERRIDE,
    GET_LAYER, GET_LAYER_MAPS, GET_LAYER_OVERRIDE, GET_LAYERS_IDS,
    UPDATE_CARTO_LAYER_BY_ID,
    UPDATE_IMAGE_BY_LAYER_ID,
    UPDATE_KML_BY_LAYER_ID,
    UPDATE_POI_BY_LAYER_ID,
    UPDATE_WMS_BY_LAYER_ID
} from "./LayerQuery";
import {Toast} from "primereact/toast";
import {InputNumber} from "primereact/inputnumber";
import {SelectBbox} from "../../ui/Selectbbox";
import {Chip} from "primereact/chip";
import {SelectPoi} from "../../ui/SelectPoi";
import {confirmPopup, ConfirmPopup} from "primereact/confirmpopup";
import {ADD_RULE_LAYER, REMOVE_RULE_LAYER} from "../rules/RuleQuery";
import {customRequest, getPadName, newUniqueId, updateOrCreate} from "../../ui/Utils";
import Upload from "./UploadInput";
import {globalToast} from "../../App";
import {LayerLinkToMap} from "./LayerLinkToMap";
import {REMOVE_MAP_LAYER_ADMIN, REMOVE_MAP_LAYER_CLIENT} from "../maps/MapQuery";


export const LayerForm = ({layer, refetch}) => {

    const [updateLayer] = useMutation(UPDATE_CARTO_LAYER_BY_ID);
    const [createLayer] = useMutation(CREATE_CARTO_LAYER);
    const [deleteLayer] = useMutation(DELETE_CARTO_LAYER_BY_ID);

    // Specific layer type
    const [updateKml] = useMutation(UPDATE_KML_BY_LAYER_ID);
    const [createKml] = useMutation(CREATE_KML_BY_LAYER_ID);
    const [deleteKml] = useMutation(DELETE_KML_BY_LAYER_ID);

    const [updateImage] = useMutation(UPDATE_IMAGE_BY_LAYER_ID);
    const [createImage] = useMutation(CREATE_IMAGE_BY_LAYER_ID);
    const [deleteImage] = useMutation(DELETE_IMAGE_BY_LAYER_ID);

    const [updateWms] = useMutation(UPDATE_WMS_BY_LAYER_ID);
    const [createWms] = useMutation(CREATE_WMS_BY_LAYER_ID);
    const [deleteWms] = useMutation(DELETE_WMS_BY_LAYER_ID);

    const [updatePoi] = useMutation(UPDATE_POI_BY_LAYER_ID);
    const [createPoi] = useMutation(CREATE_POI_BY_LAYER_ID);
    const [deletePoi] = useMutation(DELETE_POI_BY_LAYER_ID);

    const [getLayer] = useLazyQuery(GET_LAYER)
    const [getLayerMaps] = useLazyQuery(GET_LAYER_MAPS);
    const [getLayerOverride] = useLazyQuery(GET_LAYER_OVERRIDE);
    const [getLayersIds] = useLazyQuery(GET_LAYERS_IDS);

    const [upload, setUpload] = useState(false);


    const [addRule] = useMutation(ADD_RULE_LAYER)

    const [deleteAssociatedRule] = useMutation(REMOVE_RULE_LAYER);

    const [groupedMapAdmin, setGroupedMapAdmin] = useState([]);

    const { id } = useParams();

    const navigate = useNavigate();

    const [layerLinkToMapDialog, setLayerLinkToMapDialog] = useState(null);

    const {
        control,
        watch,
        handleSubmit,
        getValues,
        setValue,
        formState: {
            errors
        }
    } = useForm({
        defaultValues: {
            id: layer?.id,
            isActive: layer?.isActive,
            legendUrl: layer?.legendUrl,
            nameEn: layer?.nameEn,
            describeEn: layer?.describeEn,
            nameFr: layer?.nameFr,
            technicalName: layer?.technicalName,
            describeFr: layer?.describeFr,
            opacity: layer?.opacity,
            order: layer?.order,
            zoomMinLimit: layer?.zoomMinLimit,
            type: layer?.type,
            idBbox: layer?.idBbox,

            cartoLayerImageByIdLayer: !!layer.cartoLayerImageByIdLayer,
            cartoLayerKmlByIdLayer: !!layer.cartoLayerKmlByIdLayer,
            cartoLayerPoiByIdLayer: !!layer.cartoLayerPoiByIdLayer,
            cartoLayerWmByIdLayer: !!layer.cartoLayerWmByIdLayer,

            mapClient: layer.cartoMapLayerClientsByIdLayer?.nodes?.map((node) => node.id),
            mapAdmin: layer.cartoMapLayerAdminsByIdLayer?.nodes?.map((node) => node.id),
            rule: layer?.cartoLayerRulesMappingsByIdLayer?.nodes?.map((node) => node.cartoStaticLayerRuleByIdRule.idRule)[0],

            // WMS
            wms: {
                attribution: layer?.cartoLayerWmByIdLayer?.attribution,
                baseurl: layer?.cartoLayerWmByIdLayer?.baseurl,
                layers: layer?.cartoLayerWmByIdLayer?.layers,
                format: layer?.cartoLayerWmByIdLayer?.format,
                styles: layer?.cartoLayerWmByIdLayer?.styles,
                version: layer?.cartoLayerWmByIdLayer?.version,
                tilesize: layer?.cartoLayerWmByIdLayer?.tilesize,
                crs: layer?.cartoLayerWmByIdLayer?.crs || 'EPSG3857',
                tms: layer?.cartoLayerWmByIdLayer?.tms,
                uppercase: layer?.cartoLayerWmByIdLayer?.uppercase,
                animate: layer?.cartoLayerWmByIdLayer?.animate,
                timeLimit: layer?.cartoLayerWmByIdLayer?.timeLimit,
                nodeId: layer?.cartoLayerWmByIdLayer?.nodeId,
            },

            // KML
            kml: {
                url: layer?.cartoLayerKmlByIdLayer?.url,
                nodeId: layer?.cartoLayerKmlByIdLayer?.nodeId,
            },

            // Image
            image: {
                url: layer?.cartoLayerImageByIdLayer?.url,
                timeLimit: layer?.cartoLayerImageByIdLayer?.timeLimit,
                animate: layer?.cartoLayerImageByIdLayer?.animate,
                live: layer?.cartoLayerImageByIdLayer?.live,
            },

            // POI
            poi: {
                poiCategory: layer?.cartoLayerPoiByIdLayer?.poiCategory,
                nodeId: layer?.cartoLayerPoiByIdLayer?.nodeId,
            },

            rules: {
                id: layer?.cartoLayerRulesMappingsByIdLayer?.nodes?.map((node) => node.cartoStaticLayerRuleByIdRule.idRule)[0],
                nameRule: layer?.cartoLayerRulesMappingsByIdLayer?.nodes?.map((node) => node.cartoStaticLayerRuleByIdRule.nameRule)[0],
                group: layer?.cartoLayerRulesMappingsByIdLayer?.nodes?.map((node) => node.cartoStaticLayerRuleByIdRule.cartoLayerRulesGroupsByIdRule.nodes.map((node) => node.idGroup)).flat(),
                user: layer?.cartoLayerRulesMappingsByIdLayer?.nodes?.map((node) => node.cartoStaticLayerRuleByIdRule.cartoLayerRulesUsersByIdRule.nodes.map((node) => node.idUser)).flat(),
            }
        }
    });

    const [removeLayerAdmin] = useMutation(REMOVE_MAP_LAYER_ADMIN);
    const [removeLayerClient] = useMutation(REMOVE_MAP_LAYER_CLIENT);

    const layerType = [{
        label: 'Default',
        value: 'DEFAULT'
    }, {
        label: 'WMS',
        value: 'WMS'
    }, {
        label: 'KML',
        value: 'KML'
    }, {
        label: 'Image',
        value: 'IMAGE'
    }, {
        label: 'POI',
        value: 'POI'
    }, ]

    const watchType = watch('type');
    const watchImageLive = watch('image.live');
    const watchActive = watch('isActive');
    const toast = useRef(null);

    const isNewLayer = () => {
        return id === 'new';
    }

    useEffect(() => {
        // group admin map by id and return  a array of map containing same id
        const groupBy = (array, key) => {
            return array.reduce((result, currentValue) => {
                (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
                return result;
            }, []).filter((el) => el);
        }

        setGroupedMapAdmin(groupBy(layer?.cartoMapLayerAdminsByIdLayer?.nodes ?? [], 'idMap'));
    }, [layer]);

    const submit = (data) => {
        if (!isNewLayer()) {
            updateLayer({
                variables: {
                    id: data.id,
                    cartoLayerPatch: {
                        isActive: data.isActive,
                        legendUrl: data.legendUrl,
                        nameEn: data.nameEn,
                        describeEn: data.describeEn,
                        nameFr: data.nameFr,
                        technicalName: data.technicalName,
                        describeFr: data.describeFr,
                        opacity: data.opacity,
                        order: data.order,
                        zoomMinLimit: data.zoomMinLimit,
                        type: data.type,
                        idBbox: data.idBbox,
                    }
                }
            }).then((res) => {
                updateLayerType({
                    ...data,
                }).then((res) => {

                    toast.current.show({severity: 'success', summary: 'Success', detail: `Layer ${data.nameFr} mis à jour`, life: 3000});
                });
            });
        } else {
            createLayer({
                variables: {
                    cartoLayer: {
                        id: data.id,
                        isActive: data.isActive,
                        legendUrl: data.legendUrl,
                        nameEn: data.nameEn,
                        describeEn: data.describeEn,
                        nameFr: data.nameFr,
                        technicalName: data.technicalName,
                        describeFr: data.describeFr,
                        opacity: data.opacity,
                        order: data.order,
                        zoomMinLimit: data.zoomMinLimit,
                        type: data.type,
                        idBbox: data.idBbox,
                    }
                }
            }).then((res) => {
                updateLayerType({
                    ...data,
                });

                globalToast({severity: 'success', summary: 'Success', detail: `Layer ${data.nameFr} créé`, life: 3000});

                navigate(`/layers/${layer.id}`)
            });
        }
    }

    const duplicateLayer = async () => {
        const data = getValues();
        const listLayerIds = await getLayersIds();

        const uniqueId = newUniqueId(listLayerIds.data.allCartoLayers.nodes.map((layer) => layer.id))

        createLayer({
            variables: {
                cartoLayer: {
                    id: uniqueId,
                    isActive: data.isActive,
                    legendUrl: data.legendUrl,
                    nameEn: data.nameEn,
                    describeEn: data.describeEn,
                    nameFr: data.nameFr,
                    technicalName: data.technicalName + ' (copy)',
                    describeFr: data.describeFr,
                    opacity: data.opacity,
                    order: data.order,
                    zoomMinLimit: data.zoomMinLimit,
                    type: data.type,
                    idBbox: data.idBbox,
                }
            }
        }).then(async (res) => {
            await duplicateLayerType(res.data.createCartoLayer.cartoLayer.id);
            await duplicateLayerMaps(res.data.createCartoLayer.cartoLayer.id);
            await duplicateLayerRule(res.data.createCartoLayer.cartoLayer.id);
            await duplicateOverride(res.data.createCartoLayer.cartoLayer.id);

            globalToast({severity: 'success', summary: 'Success', detail: `Layer ${data.nameFr} a été dupliqué`, life: 1000000, content: () => (
                    <div>
                        Layer <b>{data.nameFr}</b> a été dupliqué <br/>
                        <Button label="Voir" onClick={() => navigate('/layers/' + res.data.createCartoLayer.cartoLayer.id)}  className="p-button-outlined p-button-secondary mr-2 mt-2" icon="fa-duotone fa-eye mr-2"/>
                    </div>
                )});
        }).catch((e) => {
            globalToast({severity: 'error', summary: 'Error', detail: `Une erreur est survenue`, life: 3000});
        });
    }

    const duplicateLayerType = (newLayerId) => {
        const data = getValues();
        const requests = [];

        if (data.type === 'WMS') {
            requests.push(createWms({
                variables: {
                    cartoLayerWm: {
                        idLayer: newLayerId,
                        ...data.wms
                    }
                }
            }))
        }

        if (data.type === 'KML') {
            requests.push(createKml({
                variables: {
                    cartoLayerKml: {
                        idLayer: newLayerId,
                        ...data.kml
                    }
                }
            }))
        }

        if (data.type === 'IMAGE') {
            requests.push(createImage({
                variables: {
                    cartoLayerImage: {
                        idLayer: newLayerId,
                        ...data.image
                    }
                }
            }))
        }

        if (data.type === 'POI') {
            requests.push(createPoi({
                variables: {
                    cartoLayerPoi: {
                        idLayer: newLayerId,
                        ...data.poi
                    }
                }
            }))
        }

        return Promise.all(requests);
    }
    const duplicateLayerMaps = async (newLayerId) => {
        const requests = [];

        const maps = await getLayerMaps({variables: {id: layer.id}})

        if (maps.data.cartoLayerById.cartoMapLayerAdminsByIdLayer?.nodes?.length
            || maps.data.cartoLayerById.cartoMapLayerClientsByIdLayer?.nodes?.length) {
            requests.push(customRequest('duplicateLayerMaps', DUPLICATE_LAYER_MAPS(
                newLayerId,
                maps.data.cartoLayerById.cartoMapLayerAdminsByIdLayer.nodes,
                maps.data.cartoLayerById.cartoMapLayerClientsByIdLayer.nodes
            )))
        }

        return Promise.all(requests);
    }

    const duplicateLayerRule = (newLayerId) => {
        const data = getValues();

        if (data.rule) {
            return addRule({
                variables: {
                    idLayer: newLayerId,
                    idRule: data.rule
                }
            })
        }

        return new Promise((resolve, reject) => {
            resolve()
        });
    }

    const duplicateOverride = async (newlayerId) => {
        const override = await getLayerOverride({variables: {id: layer.id}})
        const requests = [];

        if (override.data.cartoLayerById.cartoMapLayerClientOverrideUsersByIdLayer?.nodes?.length
            || override.data.cartoLayerById.cartoMapLayerClientOverrideGroupsByIdLayer?.nodes?.length) {
            requests.push(customRequest('duplicateLayerOverride', DUPLICATE_LAYER_OVERRIDE(
                newlayerId,
                override.data.cartoLayerById.cartoMapLayerClientOverrideGroupsByIdLayer.nodes,
                override.data.cartoLayerById.cartoMapLayerClientOverrideUsersByIdLayer.nodes
            )))
        }

        return Promise.all(requests);
    }


    const updateLayerType = (data) => {
        let request = null;

        switch (data.type) {
            case 'WMS':
                request = updateOrCreate(() => updateWms({
                    variables: {
                        idLayer: data.id,
                        cartoLayerWmPatch: {
                            idLayer: data.id,
                            ...data.wms
                        }
                    }
                }), () => createWms({
                    variables: {
                        cartoLayerWm: {
                            idLayer: data.id,
                            ...data.wms
                        }
                    }
                }))

                break;
            case 'KML':

                request = updateOrCreate(() => updateKml({
                    variables: {
                        idLayer: data.id,
                        cartoLayerKmlPatch: {
                            idLayer: data.id,
                            ...data.kml
                        }
                    }
                }), () => createKml({
                    variables: {
                        cartoLayerKml: {
                            idLayer: data.id,
                            ...data.kml
                        }
                    }
                }))

                break;
            case 'IMAGE':

                request = updateOrCreate(() => updateImage({
                    variables: {
                        idLayer: data.id,
                        cartoLayerImagePatch: {
                            idLayer: data.id,
                            ...data.image
                        }
                    }
                }), () => createImage({
                    variables: {
                        cartoLayerImage: {
                            idLayer: data.id,
                            ...data.image
                        }
                    }
                }))

                break;
            case 'POI':

                request = updateOrCreate(() => updatePoi({
                    variables: {
                        idLayer: data.id,
                        cartoLayerPoiPatch: {
                            idLayer: data.id,
                            ...data.poi
                        }
                    }
                }), () => createPoi({
                    variables: {
                        cartoLayerPoi: {
                            idLayer: data.id,
                            ...data.poi
                        }
                    }
                }));

                break;
            case 'DEFAULT':
                request = new Promise((resolve, reject) => {
                    resolve()
                });
                break;
            default:
                request = new Promise((resolve, reject) => {
                    resolve()
                });
                break;
        }

        return request
    }

    const disponibilityTitle = () => {
        return (<div className={
            classNames({
                'flex card-layer-title align-items-center justify-content-between': true,
                'bg-green-600': watchActive,
                'bg-red-600': !watchActive
            })
        }>
            <div className="flex align-items-center gap-2">
                <Controller
                    name="isActive"
                    control={control}
                    render={({ field, fieldState }) => (
                        <>
                            <InputSwitch checked={field.value} {...field} className={classNames({ 'p-invalid': fieldState.error })} />
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
                <label htmlFor="describeEn">
                    {watchActive ? 'Actif' : 'Inactif'}
                </label>
            </div>
        </div>)
    }

    const handleDeleteLayer = (e) => {
        confirmPopup({
            target: e.currentTarget,
            message: 'Vous êtes sûr de vouloir supprimer ce layer ?',
            icon: 'fa fa-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            focusOnShow: false,
            accept: async () => {

                deleteLayerData().then(async () => {
                    globalToast({severity: 'success', summary: 'Success', detail: `Layer ${layer.nameFr} supprimé`, life: 3000});

                    await deleteLayer({variables: {id: layer.id}})

                    navigate('/layers')
                }, () => {
                    toast.current.show({severity: 'error', summary: 'Error', detail: `Une erreur est survenue`, life: 3000});
                })
            }
        });
    }

    const deleteLayerData = async () => {
        const _layer = await getLayer({variables: {id: layer.id}})
        const request = [];

        if (_layer.data.cartoLayerById.cartoLayerImageByIdLayer) {
            request.push(deleteImage({variables: {idLayer: _layer.data.cartoLayerById.id}}))
        }

        if (_layer.data.cartoLayerById.cartoLayerKmlByIdLayer) {
            request.push(deleteKml({variables: {idLayer: _layer.data.cartoLayerById.id}}))
        }

        if (_layer.data.cartoLayerById.cartoLayerPoiByIdLayer) {
            request.push(deletePoi({variables: {idLayer: _layer.data.cartoLayerById.id}}))
        }

        if (_layer.data.cartoLayerById.cartoLayerWmByIdLayer) {
            request.push(deleteWms({variables: {idLayer: _layer.data.cartoLayerById.id}}))
        }

        if (_layer.data.cartoLayerById.cartoLayerRulesMappingsByIdLayer?.nodes.length) {
            request.push(deleteAssociatedRule({variables: {
                    idLayer: _layer.data.cartoLayerById.id,
                    idRule: _layer.data.cartoLayerById.cartoLayerRulesMappingsByIdLayer.nodes[0]?.cartoStaticLayerRuleByIdRule.idRule
                }}))
        }

        if (_layer.data.cartoLayerById.cartoMapLayerAdminsByIdLayer?.nodes?.length
            || _layer.data.cartoLayerById.cartoMapLayerClientsByIdLayer?.nodes?.length) {
            request.push(customRequest('RemoveLayerMapAssociation', DELETE_LAYER_ASSOCIATED_MAP(
                _layer.data.cartoLayerById.id,
                _layer.data.cartoLayerById.cartoMapLayerAdminsByIdLayer.nodes,
                _layer.data.cartoLayerById.cartoMapLayerClientsByIdLayer.nodes
            )))
        }

        if (_layer.data.cartoLayerById.cartoMapLayerClientOverrideUsersByIdLayer?.nodes?.length
            || _layer.data.cartoLayerById.cartoMapLayerClientOverrideGroupsByIdLayer?.nodes?.length) {

            request.push(customRequest('RemoveLayerMapClientOverride', DELETE_LAYER_ASSOCIATED_OVERRIDE(
                _layer.data.cartoLayerById.id,
                _layer.data.cartoLayerById.cartoMapLayerClientOverrideGroupsByIdLayer.nodes,
                _layer.data.cartoLayerById.cartoMapLayerClientOverrideUsersByIdLayer.nodes
            )))
        }

        return Promise.all(request);
    }

    const isPrivate = () => {
        return layer?.cartoLayerRulesMappingsByIdLayer?.nodes?.length > 0
            ? layer?.cartoLayerRulesMappingsByIdLayer?.nodes[0].cartoStaticLayerRuleByIdRule
            : null;
    }

    const isAffectedToMap = () => {
        return layer?.cartoMapLayerClientsByIdLayer?.nodes?.length > 0 || layer?.cartoMapLayerAdminsByIdLayer?.nodes?.length > 0;
    }

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : null;
    };

    const AccessAdminTemplate = (rowData) => {
        return rowData?.idTypeAdmin === "INTERNATIONAL"
            ? <Tag className="mr-2 text-white" style={{background: 'black'}} icon="pi pi-check" value="INTERNATIONAL"></Tag>
            : <Tag className="mr-2" icon="pi pi-check" style={{background: 'linear-gradient(90deg,blue 0%, white 50%, red 100%)', color:'black'}} value="FRANCE"></Tag>
    }

    const deleteLayerLinkToMap = (e, {layer, map, type}) => {
        confirmPopup({
            target: e.currentTarget,
            message: 'Vous êtes sûr de vouloir supprimer ce lien ?',
            icon: 'fa fa-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            accept: () => {
                if (type === 'CLIENT') {
                    removeLayerClient({
                        variables: {
                            idLayer: layer.id,
                            idMap: map.idMap
                        }
                    }).then(() => {
                        refetch()
                    })
                } else {
                    removeLayerAdmin({
                        variables: {
                            idLayer: layer.id,
                            idMap: map.idMap,
                            idTypeAdmin: map.idTypeAdmin
                        }
                    }).then(() => {
                        refetch()
                    })
                }
            }
        });
    }

    const mapAdminTag = () => {
        return groupedMapAdmin.map((groupedMap, index) => {
            const client = groupedMap[0];

            return (
                <div className="flex flex-column gap-1 surface-ground p-2 border-round-md justify-content-between"
                     key={'admin-' + index}>
                    <div>
                        <h3 onClick={() => navigate('/map/' + client.idMap)} className="cursor-pointer  mt-0"><i
                            className="pi pi-map "></i>
                            <Tag key={'admin-' + index}
                                 style={{background: '#f08'}}
                                 value={'ADMIN'}>
                            </Tag> <i className="underline">{client.cartoStaticMapByIdMap?.describe}</i>
                        </h3>
                    </div>

                    <div className="flex flex-column gap-4">
                        {groupedMap.map((map, index) => (
                            <div className="flex flex-row justify-content-between w-100">
                                <div className="flex gap-3 align-items-center">
                                    <div className='flex justify-content-between gap-4'>
                                        <div className="">
                                            <div>Actif par défaut : <b>{client?.isChecked ? 'Oui' : 'Non'}</b></div>
                                            <div>Pad : <b>{getPadName(client?.pad, 'ADMIN')}</b></div>
                                        </div>
                                        <div className="">
                                            <div>Zoom min: <b>{client?.zoomMin}</b></div>
                                            <div>Zoom max: <b>{client?.zoomMax}</b></div>
                                        </div>
                                        <div>
                                            {AccessAdminTemplate(map)}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-2">
                                    <Button onClick={() => setLayerLinkToMapDialog({
                                            type: 'copy',
                                            layer,
                                            map: {...map, selectedTypeInterface: 'ADMIN'}
                                        }
                                    )} className="p-button-outlined p-button-warning" icon="fa-duotone fa-copy "/>
                                    <Button onClick={() => setLayerLinkToMapDialog({
                                            type: 'edit',
                                            layer,
                                            map: {...map, selectedTypeInterface: 'ADMIN'}
                                        }
                                    )} className="p-button-outlined " icon="fa-duotone fa-pencil "/>
                                    <Button onClick={(e) => deleteLayerLinkToMap(e, {layer, map, type: 'ADMIN'})}
                                            className="p-button-outlined p-button-danger" icon="fa-duotone fa-trash "/>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            )
        })
    }

    const mapClientTag = () => {
        return layer.cartoMapLayerClientsByIdLayer?.nodes?.map((client, index) => (
            <div className="flex align-items-center gap-1 surface-ground p-2 border-round-md justify-content-between"
                 key={'admin-' + index}>
                <div>
                    <h3 onClick={() => navigate('/map/' + client.idMap)} className="cursor-pointer  mt-0"><i
                        className="pi pi-map "></i>
                        <Tag key={'admin-' + index}
                             style={{background: '#00ade5'}}
                             value={'CLIENT'}>
                        </Tag> <i className="underline">{client.cartoStaticMapByIdMap?.describe}</i>
                    </h3>
                    <div className='flex justify-content-between gap-4'>
                        <div className="">
                            <div>Actif par défaut : <b>{client?.isChecked ? 'Oui' : 'Non'}</b></div>
                            <div>Pad : <b>{getPadName(client?.pad, 'CLIENT')}</b></div>
                        </div>
                        <div className="">
                            <div>Zoom min: <b>{client?.zoomMin}</b></div>
                            <div>Zoom max: <b>{client?.zoomMax}</b></div>
                        </div>
                    </div>
                </div>

                <div className="flex gap-2">
                    <Button onClick={() => setLayerLinkToMapDialog({
                            type: 'copy',
                            layer,
                            map: {...client, selectedTypeInterface: 'CLIENT'}
                        }
                    )} className="p-button-outlined p-button-warning" icon="fa-duotone fa-copy "/>
                    <Button onClick={() => setLayerLinkToMapDialog({
                            type: 'edit',
                            layer,
                            map: {...client, selectedTypeInterface: 'CLIENT'}
                        }
                    )} className="p-button-outlined " icon="fa-duotone fa-pencil "/>
                    <Button onClick={(e) => deleteLayerLinkToMap(e, {layer, map: client, type: 'CLIENT'})}
                            className="p-button-outlined p-button-danger" icon="fa-duotone fa-trash "/>
                </div>
            </div>
        ))
    }

    return (
        <>
            <Toast ref={toast} />
            <ConfirmPopup />

            <div className="container mt-4 mx-auto" style={{maxWidth: '900px'}}>
                <div className="flex mb-4 align-items-center flex-column lg:justify-content-between lg:flex-row">
                    <div>
                        <div className="font-medium text-3xl text-900">
                            Layer > {layer?.id} {layer?.nameFr}
                        </div>
                    </div>
                    <div className="gap-2">
                        {!isNewLayer() && (<>
                            <Button label="Dupliquer" onClick={duplicateLayer}  className="p-button-outlined p-button-warning mr-2" icon="fa-duotone fa-copy mr-2" />
                            <Button label="Supprimer" onClick={handleDeleteLayer}  className="p-button-outlined p-button-danger mr-2" icon="fa-duotone fa-trash mr-2" />
                        </>)}

                        <Button label="Sauvegarder" onClick={handleSubmit(submit)} className="p-button-outlined mr-2" icon="fa-duotone fa-save" />
                    </div>
                </div>

                <div className="flex flex-column gap-3 mb-5">
                    <Card title="Config">
                        <div className="card p-fluid">
                            <div className="formgrid grid">
                                <div className="col">
                                    <div className="field">
                                        <label htmlFor="nameEn">Nom technique</label>
                                        <div>
                                            <Controller
                                                name="technicalName"
                                                control={control}
                                                rules={{ required: 'Champ obligatoire.' }}

                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} />
                                                        {getFormErrorMessage(field.name)}
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label htmlFor="nameEn">BBox</label>
                                        <div>
                                            <Controller
                                                name="idBbox"
                                                control={control}
                                                rules={{ required: 'Champ obligatoire.' }}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <SelectBbox
                                                            id={field.name}
                                                            value={field.value}
                                                            className={classNames({ 'p-invalid': fieldState.error })}
                                                            onChange={field.onChange}
                                                        />
                                                        {getFormErrorMessage(field.name)}
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>


                                    <Divider align="left">
                                        <div className="inline-flex align-items-center">
                                            <i className="pi pi-user mr-2"></i>
                                            <b>i18n</b>
                                        </div>
                                    </Divider>
                                    <div className="field">
                                        <label htmlFor="nameFr">Nom français</label>
                                        <div>
                                            <Controller
                                                name="nameFr"
                                                control={control}
                                                rules={{ required: 'Champ obligatoire.' }}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} />
                                                        {getFormErrorMessage(field.name)}
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="describeFr">Description française</label>
                                        <div>
                                            <Controller
                                                name="describeFr"
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <InputTextarea autoResize id={field.name} {...field} rows={4} cols={30} className={classNames({ 'p-invalid': fieldState.error })} />
                                                        {getFormErrorMessage(field.name)}
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="nameEn">Nom anglais</label>
                                        <div>
                                            <Controller
                                                name="nameEn"
                                                control={control}
                                                rules={{ required: 'Champ obligatoire.' }}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} />
                                                        {getFormErrorMessage(field.name)}
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="describeEn">Description anglaise</label>
                                        <div>
                                            <Controller
                                                name="describeEn"
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <InputTextarea autoResize id={field.name} {...field} rows={4} cols={30} className={classNames({ 'p-invalid': fieldState.error })} />
                                                        {getFormErrorMessage(field.name)}
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>

                    <Card title={disponibilityTitle}>
                        {!layer?.id && (
                            <div className="p-4 mt-4 border-round-sm border-1 border-orange-300 text-orange-300 flex align-items-center gap-2">
                                <i className="fa fa-exclamation-triangle mr-2"></i>
                                Vous devez sauvegarder le layer pour pouvoir lui affecter une map ou une règle
                            </div>
                        )}

                        {layer?.id && (
                            <>
                                <Divider>
                                    Affectation
                                </Divider>

                                {isAffectedToMap() && !watchActive && (
                                    <div className="p-4 flex flex-column justify-content-center align-items-center">
                                        Le layer étant inactif, il ne sera pas disponible sur les cartes suivantes :
                                    </div>
                                )}


                                {isAffectedToMap() && (
                                    <div className={classNames("flex flex-column gap-3", {
                                        'opacity-50': !watchActive
                                    })}>
                                        {mapClientTag()}
                                        {mapAdminTag()}
                                    </div>
                                )}

                                <div className="flex justify-content-center align-items-center p-4">
                                    <Button label="Lié une map" className="p-button-outlined" icon="fa fa-layer-plus"
                                            onClick={() => setLayerLinkToMapDialog({
                                                type: 'create',
                                                layer,
                                                map: {}
                                            })}
                                    />

                                    <LayerLinkToMap data={layerLinkToMapDialog} onClose={refetch}></LayerLinkToMap>
                                </div>

                                <Divider>
                                    Restrictions
                                </Divider>
                                {isPrivate() && (<Chip onClick={() => navigate('/rules/' + isPrivate().idRule)}
                                                       label={isPrivate().nameRule}
                                                       icon="fa-duotone fa-lock-keyhole"
                                                       className="ml-2 cursor-pointer"/>)}
                                {!isPrivate() && (
                                    <div className="flex justify-content-center align-items-center p-4">
                                        <Button label="Créer une règle d'accès pour ce layer" className="p-button-outlined" icon="fa fa-lock-keyhole"
                                                onClick={() => navigate('/rules/new', {
                                                    state: {idLayer: layer.id}
                                                })}
                                        />
                                    </div>
                                )}

                            </>
                        )}
                    </Card>


                    <Card title="Type de layer">
                        <div className="card p-fluid">
                            <div className="formgrid grid">
                                <div className="col">
                                    <div className="field">
                                        <Controller
                                            name="type"
                                            control={control}
                                            rules={{ required: 'Engine State is required.' }}
                                            render={({ field, fieldState }) => (
                                                <>
                                                    <SelectButton id={field.name}
                                                                  options={layerType} {...field}
                                                                  className={classNames('flex justify-content-center', { 'p-invalid': fieldState.error })} />
                                                    {getFormErrorMessage(field.name)}
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>


                            {watchType === 'WMS' && (
                                <div className="formgrid grid">
                                    <div className="col">
                                        <div className="field">
                                            <label htmlFor="baseurl">Base URL</label>
                                            <Controller
                                                name="wms.baseurl"
                                                control={control}
                                                rules={{ required: 'Base URL is required.' }}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} />
                                                        {getFormErrorMessage(field.name)}

                                                    </>
                                                )}
                                            />
                                        </div>

                                        <div className="field">
                                            <label htmlFor="layers">Layers</label>
                                            <Controller
                                                name="wms.layers"
                                                control={control}
                                                rules={{ required: 'Layers is required.' }}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} />
                                                        {getFormErrorMessage(field.name)}
                                                    </>
                                                )}
                                            />
                                        </div>

                                        <div className="field">
                                            <label htmlFor="format">Format</label>
                                            <Controller
                                                name="wms.format"
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} />
                                                        {getFormErrorMessage(field.name)}
                                                    </>
                                                )}
                                            />
                                        </div>

                                        <div className="field">
                                            <label htmlFor="styles">Styles</label>
                                            <Controller
                                                name="wms.styles"
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} />
                                                        {getFormErrorMessage(field.name)}
                                                    </>
                                                )}
                                            />
                                        </div>


                                        <div className="field">
                                            <label htmlFor="version">Version</label>
                                            <Controller
                                                name="wms.version"
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} />
                                                        {getFormErrorMessage(field.name)}
                                                    </>
                                                )}
                                            />
                                        </div>

                                        <div className="field">
                                            <label htmlFor="crs">CRS</label>
                                            <Controller
                                                name="wms.crs"
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} />
                                                        {getFormErrorMessage(field.name)}
                                                    </>
                                                )}
                                            />
                                        </div>

                                        <Divider />

                                        <div className="field flex justify-content-between align-content-center">
                                            <label htmlFor="wms.tms" className="align-self-center">TMS</label>
                                            <div>
                                                <Controller
                                                    name="wms.tms"
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <InputSwitch inputId={field.name} checked={field.value}  className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.value)} />
                                                            {getFormErrorMessage(field.name)}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <Divider />

                                        <div className="field flex justify-content-between align-content-center">
                                            <label htmlFor="wms.uppercase" className="align-self-center">Uppercase</label>
                                            <div>
                                                <Controller
                                                    name="wms.uppercase"
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <InputSwitch inputId={field.name} checked={field.value}  className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.value)} />
                                                            {getFormErrorMessage(field.name)}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <Divider />

                                        <div className="field flex justify-content-between align-content-center">
                                            <label htmlFor="wms.animate" className="align-self-center">Animate</label>
                                            <div>
                                                <Controller
                                                    name="wms.animate"
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <InputSwitch inputId={field.name} checked={field.value}  className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.value)} />
                                                            {getFormErrorMessage(field.name)}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {watchType === 'KML' && (
                                <div className="formgrid grid">
                                    <div className="col">
                                        <div className="field">
                                            <label htmlFor="baseurl">Base URL</label>
                                            <Controller
                                                name="kml.url"
                                                control={control}
                                                rules={{required: 'Base URL is required.'}}
                                                render={({field, fieldState}) => (
                                                    <>
                                                        <div className="p-inputgroup flex-1">
                                                            <InputText id={field.name} {...field}
                                                                       className={classNames({'p-invalid': fieldState.error})}/>
                                                            <Button label="Nouveau fichier" onClick={() => setUpload(!upload)} className="p-button-outlined mr-2" icon="fa-duotone fa-upload" />

                                                        </div>

                                                        {getFormErrorMessage(field.name)}
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {watchType === 'KML' && upload && (
                                <div className="formgrid grid">
                                    <div className="col">
                                        <div className="field">
                                            <Upload uploadedFileUrl={(fileUrl) => {
                                                setValue('kml.url', fileUrl)
                                                setUpload(false)
                                            }}></Upload>
                                        </div>
                                    </div>
                                </div>)}

                            {watchType === 'POI' && (
                                <div className="formgrid grid">
                                    <div className="col">
                                        <div className="field">
                                            <label htmlFor="">Type</label>
                                            <Controller
                                                name="kml.url"
                                                control={control}
                                                rules={{required: 'Base URL is required.'}}
                                                render={({field, fieldState}) => (
                                                    <>
                                                        <SelectPoi control={control}
                                                                   id={field.name}
                                                                   name={field.name}
                                                                   value={field.value}
                                                                   onChange={field.onChange}
                                                        />
                                                        {getFormErrorMessage(field.name)}
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {watchType === 'IMAGE' && (
                                <div className="formgrid grid">
                                    <div className="col">
                                        <div className="field">
                                            <label htmlFor="baseurl">Base URL</label>
                                            <Controller
                                                name="image.url"
                                                control={control}
                                                rules={{ required: 'Base URL is required.' }}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} />
                                                        {getFormErrorMessage(field.name)}
                                                    </>
                                                )}
                                            />
                                        </div>

                                        <Divider />


                                        <div className="field flex w-100 justify-content-between align-items-center">
                                            <label htmlFor="format" >Live</label>
                                            <Controller
                                                name="image.live"
                                                control={control}
                                                render={({field, fieldState}) => (
                                                    <>
                                                        <InputSwitch inputId={field.name}
                                                                     checked={field.value}

                                                                     className={classNames({'p-invalid': fieldState.error})}
                                                                     onChange={(e) => field.onChange(e.value)}
                                                        />

                                                        {getFormErrorMessage(field.name)}
                                                    </>
                                                )}
                                            />
                                        </div>

                                        {watchImageLive && (
                                            <>
                                                <Divider />

                                                <div className="field">
                                                    <label htmlFor="format">Refresh Interval</label>
                                                    <Controller
                                                        name="image.timeLimit"
                                                        control={control}
                                                        render={({field, fieldState}) => (
                                                            <>
                                                                <InputNumber id={field.name}
                                                                             {...field}
                                                                             className={classNames({'p-invalid': fieldState.error})}
                                                                             onChange={(e) => field.onChange(e.value)}
                                                                />

                                                                {getFormErrorMessage(field.name)}
                                                            </>
                                                        )}
                                                    />
                                                </div>

                                                <div className="field">
                                                    <label htmlFor="format">Animate</label>
                                                    <Controller
                                                        name="image.animate"
                                                        control={control}
                                                        render={({ field, fieldState }) => (
                                                            <>
                                                                <InputText id={field.name}
                                                                           {...field}
                                                                           className={classNames({ 'p-invalid': fieldState.error })}
                                                                />

                                                                {getFormErrorMessage(field.name)}
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Card>

                </div>
            </div>
        </>
    );
}
