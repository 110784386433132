import {gql} from "@apollo/client";


export const GET_MAPS = gql`
    query {
        allCartoStaticMaps {
            nodes {
                describe
                id
                interface
            }
        }
    }
`;



export const GET_MAP = gql`
    query GetMap($id: Int!) {
        cartoStaticMapById(id: $id) {
            interface
            describe
            id
            cartoMapLayerClientsByIdMap {
                nodes {
                    idLayer
                    isChecked
                    pad
                    zoomMax
                    zoomMin
                    cartoLayerByIdLayer {
                        nameFr
                    }
                    nodeId
                }
            }
            cartoMapLayerAdminsByIdMap {
                nodes {
                    idLayer
                    idTypeAdmin
                    isChecked
                    pad
                    zoomMax
                    zoomMin
                    cartoLayerByIdLayer {
                        nameFr
                    }
                    nodeId
                }
            }
        }
    }
`;

export const UPDATE_MAP = gql`
    mutation UpdateMap($id: Int!, $cartoStaticMapPatch: CartoStaticMapPatch!) {
        updateCartoStaticMapById(input: {
            id: $id,
            cartoStaticMapPatch: $cartoStaticMapPatch
        }) {
            cartoStaticMap {
                describe
                id
                interface
            }
        }
    }
`;

export const DELETE_MAP = gql`
    mutation DeleteMap($id: Int!) {
        deleteCartoStaticMapById(input: {
            id: $id
        }) {
            clientMutationId
        }
    }
`;

export const CREATE_MAP = gql`
    mutation CreateMap($cartoStaticMap: CartoStaticMapInput!) {
        createCartoStaticMap(input: {
            cartoStaticMap: $cartoStaticMap
        }) {
            cartoStaticMap {
                describe
                id
                interface
            }
        }
    }
`;

export const ADD_LAYER_TO_MAP_ADMIN = gql`
    mutation AddLayerToMap($cartoMapLayerAdmin: CartoMapLayerAdminInput!) {
        createCartoMapLayerAdmin(
            input: {
            cartoMapLayerAdmin: $cartoMapLayerAdmin
        }) {
            cartoMapLayerAdmin {
                nodeId
            }
        }
    }
`;

export const ADD_LAYER_TO_MAP_CLIENT = gql`
    mutation AddLayerToMap($cartoMapLayerClient: CartoMapLayerClientInput!) {
        createCartoMapLayerClient(
            input: {
            cartoMapLayerClient: $cartoMapLayerClient
        }) {
            cartoMapLayerClient {
                nodeId
            }
        }
    }
`;

export const REMOVE_MAP_LAYER_ADMIN = gql`
    mutation RemoveLayerFromMap($idMap: Int!, $idLayer: Int!, $idTypeAdmin: TypeAdmin!) {
        deleteCartoMapLayerAdminByIdMapAndIdLayerAndIdTypeAdmin(input: {
            idMap: $idMap,
            idLayer: $idLayer,
            idTypeAdmin: $idTypeAdmin,
        }) {
            clientMutationId
        }
    }
`;

export const REMOVE_MAP_LAYER_CLIENT = gql`
    mutation RemoveLayerFromMap($idMap: Int!, $idLayer: Int!) {
        deleteCartoMapLayerClientByIdMapAndIdLayer(input: {
            idMap: $idMap,
            idLayer: $idLayer,
        }) {
            clientMutationId
        }
    }
`;


export const UPDATE_MAP_LAYER_ADMIN = gql`
    mutation UpdateMapLayer(
        $idMap: Int!,
        $idLayer: Int!, 
        $idTypeAdmin: TypeAdmin!,
        $cartoMapLayerAdminPatch: CartoMapLayerAdminPatch!
    ) {
        updateCartoMapLayerAdminByIdMapAndIdLayerAndIdTypeAdmin(input: {
            idMap: $idMap,
            idLayer: $idLayer,
            idTypeAdmin: $idTypeAdmin,
            cartoMapLayerAdminPatch: $cartoMapLayerAdminPatch
        }) {
            clientMutationId
        }
    }
`;

export const UPDATE_MAP_LAYER_CLIENT = gql`
    mutation UpdateMapLayerClient($idMap: Int!, $idLayer: Int!, $cartoMapLayerClientPatch: CartoMapLayerClientPatch!) {
        updateCartoMapLayerClientByIdMapAndIdLayer(input: {
            idMap: $idMap,
            idLayer: $idLayer,
            cartoMapLayerClientPatch: $cartoMapLayerClientPatch
        }) {
            clientMutationId
        }
    }
`;


