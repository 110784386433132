import React from "react";
import {useNavigate} from "react-router-dom";

function MainNavigation() {

    const navigate = useNavigate();

    return (

        <div className="p-menubar p-component">
            <ul className="p-menubar-root-list" >
                <li className="p-menuitem">
                    <div onClick={() => navigate('/layers')} className="p-menuitem-link">
                        <span className="p-menuitem-icon fa-duotone fa-layer-group"></span>
                        <span className="p-menuitem-text">Layers</span>
                    </div>
                </li>
                <li className="p-menuitem">
                    <div onClick={() => navigate('/map')} className="p-menuitem-link">
                        <span className="p-menuitem-icon fa-duotone fa-map-location-dot"></span>
                        <span className="p-menuitem-text">Map</span></div>
                </li>
                <li className="p-menuitem">
                    <div onClick={() => navigate('/rules')} className="p-menuitem-link">
                        <span className="p-menuitem-icon fa-duotone fa-scale-balanced"></span>
                        <span className="p-menuitem-text">Rules</span>
                    </div>
                </li>
                <li className="p-menuitem">
                    <div onClick={() => navigate('/override')} className="p-menuitem-link">
                        <span className="p-menuitem-icon fa-duotone fa-wand-magic-sparkles"></span>
                        <span className="p-menuitem-text">Override</span>
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default MainNavigation;
