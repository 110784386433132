import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {SelectUsers} from "../../ui/SelectUsers";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {FilterMatchMode} from "primereact/api";
import {Chip} from "primereact/chip";
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import {
    CREATE_GROUP_LAYER_OVERRIDE,
    CREATE_USER_LAYER_OVERRIDE,
    DELETE_GROUP_LAYER_OVERRIDE,
    DELETE_USER_LAYER_OVERRIDE,
    UPDATE_GROUP_LAYER_OVERRIDE,
    UPDATE_USER_LAYER_OVERRIDE,
    GET_LAYERS_BY_MAP,
    GET_USER_BY_ID, GET_GROUP_BY_ID
} from "./SimulatorQuery";
import ModelOverrideLayer from "./ModalOverrideLayer";
import {SelectGroup} from "../../ui/SelectGroup";
import {Controller, useForm} from "react-hook-form";
import {InputText} from "primereact/inputtext";

function Simulator() {

    const { idUser, idGroup } = useParams();

    const {  data: dataMap, refetch } = useQuery(GET_LAYERS_BY_MAP);
    const [getUser] = useLazyQuery(GET_USER_BY_ID, {
        variables: { id: Number(idUser) },
    });

    const [getGroup] = useLazyQuery(GET_GROUP_BY_ID, {
        variables: { id: Number(idGroup) },
    });

    const [createUserLayerOverride] = useMutation(CREATE_USER_LAYER_OVERRIDE);
    const [updateUserLayerOverride] = useMutation(UPDATE_USER_LAYER_OVERRIDE);
    const [deleteUserLayerOverride] = useMutation(DELETE_USER_LAYER_OVERRIDE);

    const [createGroupLayerOverride] = useMutation(CREATE_GROUP_LAYER_OVERRIDE);
    const [updateGroupLayerOverride] = useMutation(UPDATE_GROUP_LAYER_OVERRIDE);
    const [deleteGroupLayerOverride] = useMutation(DELETE_GROUP_LAYER_OVERRIDE);

    const [formatedData, setFormatedData] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const [selectedLayer, setSelectedLayer] = useState(null);

    const [mapActiveLayersCount, setMapActiveLayersCount] = useState({});

    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);


    const navigate = useNavigate();

    const { control, setValue} = useForm({
        defaultValues: {
            idGroup: null,
            idUser: null,
        }
    });

    useEffect(() => {
        if (idUser) {
            setValue('idUser', idUser)
            onUserChange([idUser])
        }

        if (idGroup) {
            setValue('idGroup', idGroup)
            onGroupChange([idGroup])
        }
    }, [idUser, idGroup]);

    useEffect(() => {
        setFormatedData(formatList(dataMap));
    }, [dataMap]);

    useEffect(() => {
        setFormatedData(formatList(dataMap));
    }, [selectedUser, selectedGroup]);

    const onUserChange = (idUser) => {
        if (!idUser) return;

        setValue('idGroup', null)
        setSelectedGroup(null)

        getUser({ variables: { id: Number(idUser) } }).then((res) => {
            if (res?.data) {
                navigate(`/override/user/${res.data.usersUserByIdUser.idUser}`)
                setSelectedUser(res.data.usersUserByIdUser);
            }
        });
    }

    const onGroupChange = (idGroup) => {
        if (!idGroup) return;

        setValue('idUser', null)
        setSelectedUser(null)

        getGroup({ variables: { id: Number(idGroup) }}).then((res) => {
            if (res?.data) {
                setSelectedGroup(res.data.usersStaticGroupByIdGroup);
                navigate(`/override/group/${res.data.usersStaticGroupByIdGroup.idGroup}`)
            }
        });
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    // format data to fit the DataTable
    // object like : { map: {name, id}, layer: { name, id, pad, rule: { name, id } } }
    const formatList = (data) => {
        const list = [];

        const groupOverridesMapping = new Map();
        const userOverridesMapping = new Map();

        data?.allCartoStaticMaps?.nodes?.forEach(map => {
            map.cartoMapLayerClientOverrideGroupsByIdMap?.nodes?.forEach(group => {
                groupOverridesMapping.set(`${group.idGroup}-${group.idMap}-${group.idLayer}`, group);
            });

            map.cartoMapLayerClientOverrideUsersByIdMap?.nodes?.forEach(override => {
                userOverridesMapping.set(`${override.idUser}-${override.idMap}-${override.idLayer}`, override);
            });
        });

        data?.allCartoStaticMaps?.nodes?.forEach(map => {
            map?.cartoMapLayerClientsByIdMap?.nodes?.forEach(layer => {
                list.push({
                    name: map?.describe,
                    id: map?.id,
                    map: {
                        name: map?.describe,
                        id: map?.id,
                        layers: map?.cartoMapLayerClientsByIdMap?.nodes?.length,
                    },
                    layer: {
                        name: layer?.cartoLayerByIdLayer?.nameFr,
                        id: layer?.idLayer,
                        pad: layer?.pad,
                        isActive: layer?.cartoLayerByIdLayer?.isActive,
                        isChecked: layer?.isChecked,
                        zoomMin: layer?.zoomMin,
                        zoomMax: layer?.zoomMax,
                    },
                    override: {
                        user: userOverridesMapping.get(`${selectedUser?.idUser}-${map?.id}-${layer?.idLayer}`),
                        group: groupOverridesMapping.get(`${selectedUser?.idGroup ?? selectedGroup?.idGroup}-${map?.id}-${layer?.idLayer}`),
                    },
                    rule: selectedUserHasAccess(layer?.cartoLayerByIdLayer?.cartoLayerRulesMappingsByIdLayer, layer),
                })
            })
        })

        let filteredList = list.filter(item => item?.rule && item.layer.isActive);

        const mapLayerAccessCount = filteredList.reduce((acc, item) => {
            if (acc[item?.map?.id]) {
                acc[item?.map?.id] += 1;
            } else {
                acc[item?.map?.id] = 1;
            }
            return acc;
        }, {});

        setMapActiveLayersCount(mapLayerAccessCount);

        return filteredList;
    }

    const selectedUserHasAccess = (cartoLayerRulesMappingsByIdLayer, layer) => {

        const rules= cartoLayerRulesMappingsByIdLayer?.nodes;

        if (rules?.length === 0) {
            return true;
        }

        const userRules = rules?.filter(rule => {
            const users = rule.cartoStaticLayerRuleByIdRule?.cartoLayerRulesUsersByIdRule?.nodes;
            const groups = rule.cartoStaticLayerRuleByIdRule?.cartoLayerRulesGroupsByIdRule?.nodes;

            if (selectedUser) {
                const userHasAccess = users.filter(user => selectedUser?.idUser===  user.idUser);
                const groupHasAccess = groups.filter(group => selectedUser?.idGroup === group.idGroup);

                return userHasAccess.length > 0 || groupHasAccess.length > 0;
            } else if (selectedGroup) {
                const groupHasAccess = groups.filter(group => selectedGroup?.idGroup === group.idGroup);

                return groupHasAccess.length > 0;
            }

            return false;
        });

        return userRules?.length > 0 ? rules[0].cartoStaticLayerRuleByIdRule : false;
    }

    const renderLayerName = (data) => {
        return (
            <div className="flex align-items-center">
                <div className="mr-2">{data?.layer?.id}</div>
                <div className="text-700 text-500 text-sm cursor-pointer"
                     onClick={() => navigate('/layers/' + data.layer.id)}
                >{data?.layer?.name}</div>
            </div>
        )
    }

    const renderRule = (data) => {
        return (!!data.rule?.nameRule && <Chip
            onClick={() => navigate('/rules/' + data.rule.idRule)}
            className="ml-2 cursor-pointer"
            label={data.rule.nameRule} icon="fa-duotone fa-lock-keyhole" />) || null
    }

    const isOverrided = (title, a, b) => {
        return a !== b
            ? (<>
                {title}
                <span className="text-sm text-red-300  line-through">
                    {a.toString()}
                </span>
                <span className="font-bold text-green ml-2">
                    {b.toString()}
                </span>
            </>) : null
    }

    const renderOverride = (data) => {
        return (<div className="flex gap-2 flex-column">
            {data.override?.group && renderOverrideCard(data, 'group')}
            {data.override?.user && renderOverrideCard(data, 'user')}

            {!data.override?.group && selectedGroup && (
                <div className="inline-flex align-items-center">
                    <Button rounded outlined className="mr-2" onClick={() => editLayer(data, 'group')} >
                        <i className="fa-duotone fa-plus mr-2"></i>Override group
                    </Button>
                </div>
            )}

            {!data.override?.user && selectedUser && (
                <div className="inline-flex align-items-center">
                    <Button rounded outlined className="mr-2" onClick={() => editLayer(data, 'user')} >
                        <i className="fa-duotone fa-plus mr-2"></i>Override user
                    </Button>
                </div>
            )}
        </div>)
    }

    const renderOverrideCard = (data, type) => {
        return (
            <div className="inline-flex align-items-center w-full">
                <div style={{minWidth: 180}}
                     className="w-full border justify-content-between align-items-center border-1 p-2 border-round mr-3 border-400 flex flex-row">
                    <div className="pl-2 pr-2">
                        {type === 'user' && <i className="fa-duotone fa-user"></i>}
                        {type === 'group' && <i className="fa-duotone fa-people-group"></i>}
                    </div>
                    <div className="w-full">
                        <div
                            className="text-sm">{isOverrided('Afficher par defaut : ', data.layer.isChecked, data.override[type]?.isChecked)}</div>
                        <div
                            className="text-sm">{isOverrided('Zoom min : ', data.layer.zoomMin, data.override[type]?.zoomMin)}</div>
                        <div
                            className="text-sm">{isOverrided('Zoom max : ', data.layer.zoomMax, data.override[type]?.zoomMax)}</div>
                    </div>
                    <div className="flex flex-row gap-2">
                        <Button icon="fa-duotone fa-pen-to-square" rounded outlined onClick={() => editLayer(data)}/>
                        <Button icon="fa-duotone fa-trash" severity={"danger"} rounded outlined className="mr-2"
                                onClick={() => deleteLayerOverride(data, type)}/>
                    </div>
                </div>
            </div>
        )
    }


    const renderMapInfo = (data) => {
        return (
            <div className="inline-flex  align-items-center">
                <div className="flex flex-row">
                <div className="mr-2">{data?.id}</div>
                    <div className="text-700 text-500 text-sm cursor-pointer"
                         onClick={() => navigate('/map/' + data.id)}
                    >{data?.name}</div>
                </div>
                <div className="ml-2">
                    <span className="">({data.map.layers}/{mapActiveLayersCount[data?.id]})</span>
                </div>
            </div>
        )
    }

    const editLayer = (map, type) => {
        setSelectedLayer({
            type: selectedUser ? 'user' : 'group',
            nodeId: selectedUser ? map?.override?.user?.nodeId : map?.override?.group?.nodeId,
            idMap: Number(map?.id),
            idLayer: Number(map?.layer?.id),
            name: map?.layer?.nameFr,
            pad: map?.layer?.pad,
            isChecked: map?.layer?.isChecked,
            zoomMax: map?.layer?.zoomMax,
            zoomMin: map?.layer?.zoomMin,
        });
    };

    const deleteLayerOverride = (map, type) => {
        if (type === 'group') {
            deleteGroupLayerOverride({
                variables: {
                    idLayer: map.layer.id,
                    idMap: map.id,
                    idGroup: selectedGroup.idGroup
                }
            }).then(() => {
                refetch()
            })
        } else if (type === 'user') {
            deleteUserLayerOverride({
                variables: {
                    idLayer: map.layer.id,
                    idMap: map.id,
                    idUser: selectedUser.idUser
                }
            }).then(() => {
                refetch()
            })
        }
    }

    const handleOverride = (data) => {
        if (data.type === 'group') {
            if (data.nodeId) {
                updateGroupLayerOverride({
                    variables: {
                        idGroup: selectedGroup?.idGroup,
                        idMap: Number(data?.idMap),
                        idLayer: Number(data?.idLayer),
                        cartoMapLayerClientOverrideGroupPatch: {
                            zoomMin: Number(data?.zoomMin),
                            zoomMax: Number(data?.zoomMax),
                            isChecked: data?.isChecked,
                        }
                    }
                }).then(res => {
                    refetch().then(() => {
                        setSelectedLayer(false)
                    })
                });
                return;
            }

            createGroupLayerOverride({
                variables: {
                    idGroup: selectedGroup?.idGroup,
                    idLayer: Number(data?.idLayer),
                    idMap: Number(data?.idMap),
                    zoomMin: Number(data?.zoomMin),
                    zoomMax: Number(data?.zoomMax),
                    isChecked: data?.isChecked,
                }
            }).then(res => {
                refetch().then(() => {
                    setSelectedLayer(false)
                })
            });
            return;
        }

        if (data.nodeId) {
            updateUserLayerOverride({
                variables: {
                    idUser: selectedUser?.idUser,
                    idMap: Number(data?.idMap),
                    idLayer: Number(data?.idLayer),
                    cartoMapLayerClientOverrideUserPatch: {
                        zoomMin: Number(data?.zoomMin),
                        zoomMax: Number(data?.zoomMax),
                        isChecked: data?.isChecked,
                    }
                }
            }).then(res => {
                refetch().then(() => {
                    setSelectedLayer(false)
                })
            });
            return;
        }

        createUserLayerOverride({
            variables: {
                idUser: selectedUser?.idUser,
                idLayer: Number(data?.idLayer),
                idMap: Number(data?.idMap),
                zoomMin: Number(data?.zoomMin),
                zoomMax: Number(data?.zoomMax),
                isChecked: data?.isChecked,
            }
        }).then(res => {
            refetch().then(() => {
                setSelectedLayer(false)
            })
        });
    }

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <span className="p-input-icon-left">
                <i className="fa-duotone fa-search" />
                <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Rechercher..." />
            </span>
        );
    };

    return (
        <div className="container mt-4 mx-auto">
            <div className="flex mb-4 align-items-start flex-column lg:justify-content-between lg:flex-row">
                <div>
                    {selectedUser && (
                        <div className="font-medium text-3xl text-900 flex flex-row gap-2 align-items-center">
                            <i className="fa-duotone fa-user"></i>
                            <span>User > {selectedUser?.nameUser}</span>
                            <Button icon="fa-duotone fa-times" rounded severity="danger" outlined onClick={() => {
                                setValue('idUser', null)
                                setSelectedUser(null)
                                navigate('/override')
                            }}/>
                        </div>)}
                    {selectedGroup && (
                        <div className="font-medium text-3xl text-900 flex flex-row gap-2 align-items-center">
                            <i className="fa-duotone fa-people-group mr-2"></i>
                            <span>Group > {selectedGroup?.nameGroup}</span>
                            <Button icon="fa-duotone fa-times" rounded severity="danger" outlined onClick={() => {
                                setValue('idGroup', null)
                                setSelectedGroup(null)
                            }}/>

                        </div>)}
                    <div className="flex align-items-center text-700 flex-wrap">
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-users mr-2"></i>
                        </div>
                    </div>
                </div>
            </div>

            <Card className="mb-3">
                <div className="flex gap-3">
                    <div className="flex-grow-1">
                        <Controller
                            name="idUser"
                            control={control}
                            render={({field, fieldState}) => (
                                <SelectUsers control={control}
                                             id={field.name}
                                             multiple={false}
                                             single={true}
                                             name={field.name}
                                             value={field.value}
                                             onChange={(e) => {
                                                 field.onChange(e)
                                                 onUserChange(e)
                                             }}/>
                            )}
                        />
                    </div>
                    <div className="flex-grow-1">
                        <Controller
                            name="idGroup"
                            control={control}
                            render={({field, fieldState}) => (
                                <SelectGroup control={control}
                                             id={field.name}
                                             single={true}
                                             name={field.name}
                                             value={field.value}
                                             onChange={(e) => {
                                                 field.onChange(e)
                                                 onGroupChange(e)
                                             }}/>
                            )}
                        />
                    </div>
                </div>

                {!selectedUser && !selectedGroup && (<>
                        <div className="bg-blue-700 border border-1 border-round border-blue-500 p-3 flex gap-3 mt-3">
                            <i className="fa-duotone fa-circle-info"></i>
                            <div>Sélectionnez un <b>utilisateur</b> ou
                            un <b>groupe</b> pour pouvoir gérer leurs overrides.
                            </div>
                        </div>
                        <div
                            className="bg-orange-700 border border-1 border-round border-orange-500 p-3 flex gap-3 mt-3">
                            <i className="fa-duotone fa-circle-question"></i>
                            <div>
                                Si vous ne sélectionnez pas d'utilisateur
                                ou de groupe, seul les layers <b>publics</b> et <b>affecté</b> à une map <b>client</b> seront listés. <i>Ceci à titre
                                informatif, aucun override ne sera possible.</i>
                            </div>
                        </div>
                    </>
                )}

                {selectedUser && (
                    <div className="bg-blue-700 border border-1 border-round border-blue-500 p-3 flex gap-3 mt-3">
                        <i className="fa-duotone fa-circle-info"></i>Les overrides de l'utilisateur <b><i
                        className="fa-duotone fa-user mr-2"></i>{selectedUser?.nameUser}</b> seront toujours
                        prioritaires sur les overrides du groupe <b><i
                        className="fa-duotone fa-people-group mr-2"></i>{selectedUser.usersStaticGroupByIdGroup?.nameGroup}
                    </b>
                    </div>
                )}

                {selectedGroup && (
                    <div className="bg-blue-700 border border-1 border-round border-blue-500 p-3 flex gap-3 mt-3">
                        <i className="fa-duotone fa-circle-info"></i>Les override des utilisateurs seront prioritaires
                        sur les overrides du groupe <b><i
                        className="fa-duotone fa-people-group mr-2"></i>{selectedGroup?.nameGroup}</b>
                    </div>
                )}
            </Card>

            <Card>
                <DataTable value={formatedData}
                           groupRowsBy={'id'}
                           rowGroupMode={'subheader'}
                           header={renderHeader}
                           rowGroupHeaderTemplate={renderMapInfo}
                           expandableRowGroups={true}
                           filters={filters}
                           emptyMessage={'Aucun résultat'}
                           onFilter={(e) => setFilters(e.filters)}
                           expandedRows={expandedRows}
                           onRowToggle={(e) => setExpandedRows(e.data)}
                           tableStyle={{minWidth: '100%'}}>
                    <Column header="Map" filterField={'name'}></Column>
                    <Column header="Layer" filterField={'layer.name'} body={renderLayerName}></Column>
                    <Column header="Rule" field="rule" body={renderRule}></Column>
                    <Column header="Override" field="layer.id" body={renderOverride}></Column>
                </DataTable>
            </Card>
            <ModelOverrideLayer el={selectedLayer} onMutation={handleOverride}/>
        </div>
    );
}

export default Simulator;