
import React, { useEffect, useState } from 'react';
import {gql, useQuery} from "@apollo/client";
import {MultiSelect} from "primereact/multiselect";
import {Skeleton} from "primereact/skeleton";
import {Dropdown} from "primereact/dropdown";

const GET_GROUPS = gql`
    query GetGroups {
        allUsersStaticGroups {
            nodes {
                idGroup
                nameGroup
                nameSupergroup
            }
        }
    }
`;

export const SelectGroup = ({ single, name, id, label, value, disabled, onChange, ...props }) => {

    const { data } = useQuery(GET_GROUPS);

    const [groups, setGroups] = useState([]);


    useEffect(() => {
        if (data) {
            const _groups = data.allUsersStaticGroups.nodes.map(group => {
                return {
                    ...group,
                    value: group.idGroup,
                    name: `${group.nameGroup} - ${group.idGroup}`
                }
            })

            setGroups(_groups);
        }
    }, [data]);

    const _onChange = (e) => {
        onChange(e.value);
    }

    if (!groups.length) {
        return <Skeleton width="100%" height={'44px'}></Skeleton>
    }

    if (single) {
        return <Dropdown
            id={id}
            name={name}
            value={value}
            options={groups}
            onChange={_onChange}
            placeholder="Sélectionner un groupe"
            optionLabel="name"
            filter
            filterBy={"name, value"}
            disabled={disabled}
            className={"w-full"}
        />
    }

    return  (
        <MultiSelect id={id}
                     name={name}
                     value={value}
                     filter
                     className={"w-full"}
                     disabled={disabled}
                     filterBy={"name, value"}
                     display={"chip"}
                     virtualScrollerOptions={{ itemSize: 43 }}
                     options={groups}
                     optionLabel="name"
                     onChange={_onChange}
                     placeholder={"Sélectionner un ou plusieurs groupes"}
        />
    )

}