import {Controller, useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import React, {useState} from "react";
import {SelectGroup} from "../../ui/SelectGroup";
import {SelectUsers} from "../../ui/SelectUsers";
import {SelectLayer} from "../../ui/SelectLayer";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {CREATE_RULE_MUTATION, DELETE_RULE_MUTATION, UPDATE_RULE_MUTATION} from "./RuleQuery";
import {Toast} from "primereact/toast";
import {useMutation} from "@apollo/client";
import {customRequest} from "../../ui/Utils";

export const RuleForm = ({rule}) => {

    const [isLoading, setIsLoading] = useState(false);
    const toast = React.useRef(null);
    const [createRule] = useMutation(CREATE_RULE_MUTATION);
    const [deleteRule] = useMutation(DELETE_RULE_MUTATION);

    const navigate = useNavigate();

    const { id } = useParams();

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            name: rule?.nameRule,
            idRule: rule?.idRule,
            layer: rule?.layer || rule?.cartoLayerRulesMappingsByIdRule?.nodes.map((node) => node.cartoLayerByIdLayer.id),
            group: rule?.cartoLayerRulesGroupsByIdRule?.nodes?.map((node) => node.usersStaticGroupByIdGroup.idGroup),
            user: rule?.cartoLayerRulesUsersByIdRule?.nodes?.map((node) => node.usersUserByIdUser.idUser),
            userExcluded: rule?.cartoLayerRulesExemptUsersByIdRule?.nodes?.map((node) => node.idUser),
        }
    });

    const submit = (data) => {
        setIsLoading(true);

        if (id === 'new') {
            createRule({
                variables: {
                    name: data.name,
                    idRule: data.idRule
                }
            }).then((res) => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Rule Created', life: 3000 });
                setIsLoading(false);
                if (data.layer || data.group || data.user) {
                    update(data)
                }

                navigate('/rules/' + data.idRule)
            })
        } else {
            update(data)
        }
    }

    const update = (data) => {
        const test = UPDATE_RULE_MUTATION(rule?.idRule, data?.name,
            rule?.cartoLayerRulesMappingsByIdRule?.nodes.map((node) => node.cartoLayerByIdLayer.id),
            rule?.cartoLayerRulesGroupsByIdRule?.nodes?.map((node) => node.usersStaticGroupByIdGroup.idGroup),
            rule?.cartoLayerRulesUsersByIdRule?.nodes?.map((node) => node.usersUserByIdUser.idUser),
            rule?.cartoLayerRulesExemptUsersByIdRule?.nodes?.map((node) => node.idUser),
            data?.layer,
            data?.group,
            data?.user,
            data?.userExcluded
        );

        customRequest('UpdateRule', test)
            .then((res) => {
                setIsLoading(false);
                toast.current.show({ severity: 'success', summary: 'Success', detail: `${data.name} mis à jour`, life: 3000 });

                // reset cache
            })
            .catch((err) => {
                setIsLoading(false);
                console.error(err);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Une erreur est survenue', life: 6000 });
            });
    }

    const removeRule = () => {

        update({name: rule?.nameRule, idRule: rule?.idRule, layer: [], group: [], user: [], userExcluded: [] }).then((res) => {
            deleteRule({
                variables: {
                    idRule: rule?.idRule
                }
            }).then((res) => {
                setIsLoading(false);
                navigate('/rules')
            });
        });
    }


    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    return (<>
        <Toast ref={toast} />
        <div className="container mt-4 mx-auto" style={{maxWidth: '900px'}}>
            <div className="flex mb-4 align-items-start flex-column lg:justify-content-between lg:flex-row">
                <div>
                    <div className="font-medium text-3xl text-900">
                        {id === 'new' ? 'Créer une règle' : 'Modifier la règle'} {rule?.nameRule}
                    </div>
                </div>
                <div className="lg:ml-2 gap-2">
                    {id !== 'new' && (
                        <Button label="Supprimer" disabled={isLoading}
                                severity={'danger'}
                                onClick={removeRule}
                                className="p-button-outlined mr-2"
                                icon="fa-duotone fa-trash" />
                    )}

                    <Button label={id === 'new' ? 'Créer' : 'Modifier'}
                            disabled={isLoading}
                            onClick={handleSubmit(submit)}
                            className="p-button-outlined mr-2"
                            icon="fa-duotone fa-save" />
                </div>
            </div>

            <div className="flex flex-column gap-3 mb-5">

                <Card>
                    <div className="field">
                        <label htmlFor="nameEn">Nom</label>
                        <div>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: 'Name is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error, 'w-full': true })} />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div className="field">
                        <label htmlFor="describeEn">Layers concernés</label>
                        <div>
                            <Controller
                                name="layer"
                                control={control}
                                render={({ field, fieldState, onChange }) => (
                                    <SelectLayer control={control}
                                                 id={field.name}
                                                 name={field.name}
                                                 value={field.value}
                                                 onChange={field.onChange}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className="field">
                        <label htmlFor="describeEn">Accès usager</label>
                        <div>
                            <Controller
                                name="user"
                                control={control}
                                render={({ field, fieldState, onChange }) => (
                                    <SelectUsers control={control}
                                                 id={field.name}
                                                 name={field.name}
                                                 value={field.value}
                                                 onChange={field.onChange}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className="field">
                        <label htmlFor="describeEn">Accès group</label>
                        <div>
                            <Controller
                                name="group"
                                control={control}
                                render={({ field, fieldState, onChange }) => (
                                    <SelectGroup control={control}
                                                 id={field.name}
                                                 name={field.name}
                                                 value={field.value}
                                                 onChange={field.onChange}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </Card>

                <Card>
                    <div className="bg-blue-700 border border-1 border-round border-blue-500 p-3  gap-3 mb-3">
                        <i className="fa-duotone fa-circle-info mr-2"></i> Les usager présent dans la liste des usagers exclu ne pourront pas accéder au layers concernés par cette règle.
                        <p>
                            <i>
                                <b>Exemple</b> : Si la règle concerne les layers <b>layer1</b> et <b>layer2</b> et que l'usager <b>user1</b> fait partie du groupe <b>group1</b> présent dans la liste ci-dessus, alors l'usager <b>user1</b> ne pourra pas accéder aux layers <b>layer1</b> et <b>layer2</b>.
                            </i>
                        </p>
                    </div>

                    <div className="field">
                        <label htmlFor="userExcluded">Usager exclu</label>
                        <div>
                            <Controller
                                name="userExcluded"
                                control={control}
                                render={({ field, fieldState, onChange }) => (
                                    <SelectUsers control={control}
                                                 id={field.name}
                                                 name={field.name}
                                                 value={field.value}
                                                 onChange={field.onChange}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    </>);
}
