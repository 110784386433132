import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function EmptyTable(headers, rows) {
    const items = Array.from({ length: rows }, (v, i) => i);

    const bodyTemplate = () => {
        return <Skeleton></Skeleton>
    }

    return (
        <div className="card">
            <DataTable value={items} className="p-datatable-striped">
                {headers.map((header, index) => {
                    return <Column key={index} field={header} header={header} body={bodyTemplate} sortable></Column>
                })}
            </DataTable>
        </div>
    );
}
