
import React, { useEffect, useState } from 'react';
import {gql, useQuery} from "@apollo/client";
import {Skeleton} from "primereact/skeleton";
import {Dropdown} from "primereact/dropdown";

const GET_BBOX = gql`
    query GetBbox {
        allCartoBboxes {
            nodes {
                id
                name
            }
        }
    }
`;

export const SelectBbox = ({ name, id, value, onChange, className }) => {

    const { data } = useQuery(GET_BBOX);

    const [bbox, setBbox] = useState([]);


    useEffect(() => {
        if (data) {
            const _bboxs = data.allCartoBboxes.nodes.map(group => {
                return {
                    ...group,
                    value: group.id,
                }
            })

            setBbox(_bboxs);
        }
    }, [data]);


    if (!bbox.length) {
        return <Skeleton width="100%" height={'44px'}></Skeleton>
    }

    return  (
        <Dropdown id={id}
                  name={name}
                  value={value}
                  className={className}
                  disabled={!bbox.length}
                  optionValue={"id"}
                  optionLabel={"name"}
                  filter={true}
                  onChange={(e) => onChange(e.value)}
                  options={bbox}
        />
    )

}