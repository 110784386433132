
import React, { useEffect, useState } from 'react';
import {Skeleton} from "primereact/skeleton";
import {Dropdown} from "primereact/dropdown";

export const SelectPoi = ({ name, id, value, onChange, className }) => {

    const [dataAllPOIcategories, setDataAllPOIcategories] = useState([]);

    const getAllPOICategories = () => {
        fetch('http://igopredict.igo.fr/PredictAnalyzer/WebServices/POI.aspx?mode=getType')
            .then(response => response.json())
            .then(response => {
                setDataAllPOIcategories(response?.list ?? []);
            })
            .catch(err => {console.log(err)});
    }

    useEffect(() => {
        getAllPOICategories();
    }, []);

    if (!dataAllPOIcategories.length) {
        return <Skeleton width="100%" height={'44px'}></Skeleton>
    }

    return  (
        <Dropdown id={id}
                  name={name}
                  value={value}
                  className={className}
                  disabled={!dataAllPOIcategories.length}
                  optionValue={"id"}
                  optionLabel={"nom"}
                  filter={true}
                  onChange={(e) => onChange(e.value)}
                  options={dataAllPOIcategories}
        />
    )

}